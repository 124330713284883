import { isRejectedWithValue, Middleware, UnknownAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { generatedApi, SdValidationError } from 'redux/services/api.generated';
import { showMessage } from 'redux/slices/messageSlice';
import { bffUserEndpointName } from 'redux/services/bffUserApi';

type Endpoint = keyof typeof generatedApi.endpoints | typeof bffUserEndpointName;
const apiEndpoints = [...Object.keys(generatedApi.endpoints), bffUserEndpointName];

const unusedEndpoints = [
  'deactivateSubscriptionLine',
  'discontinueSubscriptionLine',
  'reactivateSubscriptionLine',
  'exportSubscription',
  'exportSubscriptionLines',
  bffUserEndpointName,
] as const;

type UsedEndpoint = Exclude<Endpoint, typeof unusedEndpoints[number]>;

const usedEndpoints = apiEndpoints.filter(
  // @ts-ignore Array.prototype.includes assumes that the value to be checked is
  // of the same or narrower type than the array's elements.
  (endpoint: Endpoint) => !unusedEndpoints.includes(endpoint),
);

interface Error {
  data: SdValidationError[];
  status: number;
}

interface Action extends UnknownAction {
  meta?: {
    arg?: {
      endpointName: UsedEndpoint;
    };
  };
}

export const errorHandler: Middleware =
  ({ dispatch }) =>
  (next) =>
  (unknownAction) => {
    // Typecast to resolve TS error.
    const action = unknownAction as Action;
    if (!action.meta?.arg?.endpointName) {
      return next(action);
    }
    const endpointName = action.meta.arg.endpointName;
    if (isRejectedWithValue(action) && usedEndpoints.includes(endpointName)) {
      console.error(
        action.meta.arg.endpointName,
        action.payload as Error,
        action.error,
      );
      // This will give a TS error if an endpoint is added, and translation is not added.
      const message = t(`error.${endpointName}`);
      dispatch(showMessage({ text: message, color: 'danger' }));
    }
    return next(action);
  };
