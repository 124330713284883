import {
  GridRowId,
  GridToolbarContainer,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Button, Input, Switch, Typography } from '@mui/joy';
import { CalendarOffIcon, DownloadIcon, RecycleIcon, Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import styles from './Toolbar.module.css';
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react';
import {
  CollectionModel,
  LineStatus,
  StandardModel,
  useBulkReactivateSubscriptionLinesMutation,
} from 'redux/services/api.generated';
import { showMessage } from 'redux/slices/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import ReactivationModal from 'components/pages/SubscriptionLines/SubscriptionLinesDataGrid/ReactivationModal/ReactivationModal';
import { downloadFile } from 'util/fileUtil';
import DiscontinueSubscriptionLineModal from '../../DiscontinueSubscriptionLineModal/DiscontinueSubscriptionLineModal';
import Spinner from 'components/global/Spinner/Spinner';
import { buttonIconSize } from 'util/constants';

interface ToolbarProps {
  enableSearch: boolean;
  includeStandardsInCollections: boolean;
  setIncludeStandardsInCollections: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  datePickerMaxDate?: string;
}

const Toolbar = ({
  enableSearch,
  includeStandardsInCollections,
  setIncludeStandardsInCollections,
  refetch,
  datePickerMaxDate,
}: ToolbarProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();
  const selectedRows = apiRef.current.getSelectedRows() as Map<
    GridRowId,
    StandardModel | CollectionModel
  >;
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [discontinueModalOpen, setDiscontinueModalOpen] = useState(false);
  const [reactivationModalState, setReactivationModalState] = useState({
    open: false,
    warningMessage: '',
  });
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [reactivateSubscriptionLines] = useBulkReactivateSubscriptionLinesMutation();

  const itemsToReactivate: number[] = [];
  selectedRows.forEach((row) => {
    if (
      [
        LineStatus.Inactive,
        LineStatus.Expired,
        LineStatus.PendingInactive,
        LineStatus.Discontinued,
      ].includes(row.lineStatus)
    ) {
      itemsToReactivate.push(row.id);
    }
  });

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const debounceTimeout = 300;

    timerRef.current = setTimeout(() => {
      apiRef.current.upsertFilterItem({
        id: 'productSearch',
        field: 'name',
        operator: 'contains',
        value: event.target.value,
      });
    }, debounceTimeout);
  };

  const products: Array<{
    id: number;
    name: string;
    status: StandardModel['lineStatus'];
  }> = [];
  const collections: Array<{
    id: number;
    name: string;
    status: CollectionModel['lineStatus'];
  }> = [];
  selectedRows.forEach((row) => {
    // Use 'collectionStandardCount' to determine if it's a collection or a product.
    if ('collectionStandardCount' in row) {
      collections.push({
        id: row.id,
        name: row.name ? row.name : '',
        status: row.lineStatus,
      });
    } else {
      products.push({
        id: row.id,
        name: row.name ? row.name : '',
        status: row.lineStatus,
      });
    }
  });

  const toggleIncludeCollectionItems = (event: ChangeEvent<HTMLInputElement>) => {
    setIncludeStandardsInCollections(event.target.checked);
  };

  const onReactivateClick = () => {
    if (!itemsToReactivate?.length) {
      setReactivationModalState({
        open: true,
        warningMessage: t('subscriptions.noItemsToReactivate'),
      });
      return;
    }

    if (itemsToReactivate.length !== selectedRows.size) {
      setReactivationModalState({
        open: true,
        warningMessage: t('subscriptions.numberOfNumberWillBeReactivated', {
          number: itemsToReactivate.length,
          total: selectedRows.size,
        }),
      });
    } else {
      reactivateSubscriptionLines({
        bulkReactivateSubscriptionLinesCommand: {
          subscriptionLineIds: itemsToReactivate,
        },
      })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(
            showMessage({
              text: t('subscriptions.successfullyReactivated'),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are caught in middleware.
        });
    }
  };

  const onExportClick = async () => {
    const itemsToExport: number[] = [];
    selectedRows.forEach((row) => {
      itemsToExport.push(row.id);
    });
    setIsDownloadingFile(true);
    const result = await downloadFile(
      '/api/subscription-lines/export',
      '',
      JSON.stringify({
        subscriptionLineIds: itemsToExport,
      }),
    );
    if (!result) {
      dispatch(
        showMessage({
          text: t('general.exportSelectedError'),
          color: 'danger',
        }),
      );
    }
    setIsDownloadingFile(false);
  };

  return (
    <GridToolbarContainer className={styles.toolbar}>
      <ReactivationModal
        warningMessage={reactivationModalState.warningMessage}
        open={reactivationModalState.open}
        setModalState={setReactivationModalState}
        itemsToReactivate={itemsToReactivate}
        refetch={refetch}
      />
      <DiscontinueSubscriptionLineModal
        isOpen={discontinueModalOpen}
        setIsOpen={setDiscontinueModalOpen}
        subscriptionLines={collections.length > 0 ? collections : products}
        refetch={refetch}
        datePickerMaxDate={datePickerMaxDate}
      />
      <div className={styles.buttonContainer}>
        <Button
          variant="outlined"
          size="sm"
          startDecorator={<CalendarOffIcon size={buttonIconSize} />}
          disabled={!selectedRows.size}
          onClick={() => setDiscontinueModalOpen(true)}
        >
          {t('subscriptions.deactivateSelected')}
        </Button>
        <Button
          variant="outlined"
          size="sm"
          startDecorator={<RecycleIcon size={buttonIconSize} />}
          disabled={!selectedRows.size}
          onClick={onReactivateClick}
        >
          {t('subscriptions.reactivateSelected')}
        </Button>
        <Button
          variant="outlined"
          size="sm"
          startDecorator={
            isDownloadingFile ? (
              <Spinner size="xs" />
            ) : (
              <DownloadIcon size={buttonIconSize} />
            )
          }
          disabled={!selectedRows.size || isDownloadingFile}
          onClick={() => void onExportClick()}
          className={styles.downloadButton}
        >
          {t('general.exportSelected')}
        </Button>
      </div>
      {Boolean(setIncludeStandardsInCollections) && (
        <div className={styles.inputWrapper}>
          <Switch
            checked={includeStandardsInCollections}
            onChange={toggleIncludeCollectionItems}
            className={styles.switch}
            id="include-switch"
          />
          <Typography component="label" level="labelSm" htmlFor="include-switch">
            {t('subscriptions.includeStandardsInCollection')}
          </Typography>
        </div>
      )}
      {enableSearch && (
        <div className={styles.inputWrapper}>
          <Typography component="label" level="labelSm" htmlFor="product-search">
            {t('general.productSearch')}
          </Typography>
          <Input
            color="neutral"
            size="sm"
            name="product-search"
            id="product-search"
            onChange={onInputChange}
            endDecorator={<Search size={buttonIconSize} />}
            value={inputValue}
          />
        </div>
      )}
      <Button
        size="sm"
        variant="outlined"
        onClick={() => {
          setInputValue('');
          apiRef.current.setFilterModel({
            items: [{ field: 'product', operator: 'contains', value: '' }],
          });
        }}
      >
        {t('general.resetFilters')}
      </Button>
    </GridToolbarContainer>
  );
};

export default Toolbar;
