import enGB from 'date-fns/locale/en-GB';
import nb from 'date-fns/locale/nb';

export const appName = 'Subscription Management';

export const allowedUserRoles = ['Administrators', 'SALES'];

export const dateLocales = {
  EN: enGB,
  NO: nb,
};

export const buttonIconSize = '1.1428574em';
