import { saveAs } from 'file-saver';
import { SubscriptionModel } from 'redux/services/api.generated';

/**
 * Gets filename from content disposition header as passed from the back end.
 * @param {Headers} headers - response headers.
 * @returns {string | undefined} - extracted filename.
 */
const getFileNameFromContentDisposition = (headers: Headers): string | undefined => {
  const contentDisposition = headers.get('Content-Disposition');
  return contentDisposition
    ? contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '')
    : undefined;
};

const saveFile = async (response: Response, name: string) => {
  const fileName = getFileNameFromContentDisposition(response.headers) ?? name;
  const blob = await response.blob();
  saveAs(blob, fileName);
};

export const downloadFile = async (
  endpoint: string,
  name: string,
  body?: string,
): Promise<boolean> => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'X-CSRF': '1',
    },
    body,
  });
  if (response.ok) {
    await saveFile(response, name);
    return true;
  }
  return false;
};

/**
 *  Gets the endpoint URL for exporting a file with subscription data.
 *  @param {string} subscriptionId - the id of the subscription to export.
 *  @returns {string} - the URL of the export subscription endpoint.
 */
export const getSubscriptionExportUrl = (
  subscriptionId: SubscriptionModel['subscriptionId'],
): string => `/api/subscriptions/${subscriptionId}/export`;
