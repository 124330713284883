import FindSubscribers from './FindSubscribers/FindSubscribers';
import SubscriptionList from './SubscriptionList/SubscriptionList';
import styles from './Subscriptions.module.css';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../redux/hooks';
import { setAppTitle } from '../../../redux/slices/appSlice';
import { SubscriberModel } from 'redux/services/api.generated';

const Subscriptions = () => {
  const dispatch = useAppDispatch();

  const [selectedSubscriber, setSelectedSubscriber] = useState<
    SubscriberModel | undefined
  >();

  useEffect(() => {
    dispatch(setAppTitle({}));
  }, [dispatch]);

  return (
    <main className={styles.main}>
      <FindSubscribers
        setSelectedSubscriber={setSelectedSubscriber}
        selectedSubscriber={selectedSubscriber}
        className={styles.findSubscribers}
      />
      <SubscriptionList selectedSubscriber={selectedSubscriber} />
    </main>
  );
};

export default Subscriptions;
