import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
    baseUrl: '/api',
  }),
  // We don't define any endpoints here. They are injected from separate files.
  endpoints: () => ({}),
  tagTypes: ['Subscription', 'SubscriptionLines'],
});
