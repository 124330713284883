import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Alert } from '@mui/joy';
import styles from './Message.module.css';
import { clearMessage } from 'redux/slices/messageSlice';
import { useAppDispatch } from 'redux/hooks';

const Message = () => {
  const dispatch = useAppDispatch();

  const { text, color } = useSelector((state: RootState) => state.message);

  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [text]);

  const handleClose = (event: SyntheticEvent | Event, type: SnackbarCloseReason) => {
    if (type !== 'clickaway') {
      setOpen(false);
      dispatch(clearMessage());
    }
  };

  if (!text) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      className={styles.snackbar}
    >
      <Alert variant="solid" color={color}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Message;
