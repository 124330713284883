import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertProps } from '@mui/joy';

interface MessageState {
  color?: AlertProps['color'];
  text?: string;
}

const initialState: MessageState = {};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<MessageState>) {
      state.text = action.payload.text;
      state.color = action.payload.color;
    },
    clearMessage(state) {
      state.text = undefined;
      state.color = undefined;
    },
  },
});

export const {
  actions: { showMessage, clearMessage },
  reducer: messageReducer,
} = messageSlice;
