import { CircularProgress } from '@mui/joy';
import styles from './Spinner.module.css';

interface SpinnerProps {
  position?: 'centerFixed' | 'centerAbsolute';
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Spinner = ({ position, size = 'lg' }: SpinnerProps) => {
  let spinnerStyles = styles.spinnerWrapper;
  if (position) {
    spinnerStyles += ` ${styles[position]}`;
  }
  return (
    <div className={spinnerStyles}>
      <CircularProgress size={size} />
    </div>
  );
};

export default Spinner;
