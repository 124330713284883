import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/joy';
import styles from './DiscontinueModal.module.css';
import { useTranslation } from 'react-i18next';
import { formatDate, getDatePickerFormat } from '../../../util/util';
import { DatePicker } from '@mui/x-date-pickers';

export enum DeactivationMethod {
  discontinue = 'discontinue',
  deactivateToday = 'deactivateToday',
  deactivateOnDate = 'deactivateOnDate',
}

interface DiscontinueModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (
    event: FormEvent<HTMLFormElement>,
    inactiveDate: Date | null,
    closeModal: () => void,
    deactivationMethod: DeactivationMethod,
  ) => void;
  title: string;
  description?: string;
  invoicePeriodEndDate?: string | null;
  disableSubmit?: boolean;
  datePickerMaxDate?: string;
}

const DiscontinueModal = ({
  isOpen,
  setIsOpen,
  handleSubmit,
  title,
  description,
  invoicePeriodEndDate,
  disableSubmit = false,
  datePickerMaxDate,
}: DiscontinueModalProps) => {
  const { t } = useTranslation();

  const [deactivationMethod, setDeactivationMethod] = useState(
    DeactivationMethod.discontinue,
  );
  const [inactiveDate, setInactiveDate] = useState<Date | null>(null);
  const [isDateValid, setIsDateValid] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    setDeactivationMethod(DeactivationMethod.discontinue);
    setInactiveDate(null);
  };

  const radioLabel = (label: string) => (
    <>
      <span className={styles.notSelected} aria-hidden>
        {label}
      </span>
      <span className={styles.selected}>{label}</span>
    </>
  );

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={styles.modalContainer}
    >
      <ModalDialog variant="plain" className={styles.modal}>
        <ModalClose aria-label={t('general.close')} />
        <form
          onSubmit={(event) =>
            handleSubmit(event, inactiveDate, closeModal, deactivationMethod)
          }
        >
          <Typography
            id="modal-title"
            level="heading4"
            component="h2"
            className={styles.title}
          >
            {title}
          </Typography>
          {description && (
            <Typography id="modal-description">{description}</Typography>
          )}
          <RadioGroup
            value={deactivationMethod}
            onChange={(event) =>
              setDeactivationMethod(event.target.value as DeactivationMethod)
            }
            className={styles.radioGroup}
          >
            <Radio
              value={DeactivationMethod.discontinue}
              label={radioLabel(
                `${t('subscriptions.discontinueAtEndOfInvoicePeriod')} ${
                  invoicePeriodEndDate ? `(${formatDate(invoicePeriodEndDate)})` : ''
                }`,
              )}
            />
            <Radio
              value={DeactivationMethod.deactivateToday}
              label={radioLabel(t('subscriptions.deactivateToday'))}
            />
            <div className={styles.datePickerWrapper}>
              <Radio
                value={DeactivationMethod.deactivateOnDate}
                label={radioLabel(t('subscriptions.markForDeactivation'))}
              />
              <DatePicker
                disabled={deactivationMethod !== DeactivationMethod.deactivateOnDate}
                value={inactiveDate || null}
                onChange={(newValue) => setInactiveDate(newValue)}
                onError={(message) => setIsDateValid(message === null)}
                disablePast
                format={getDatePickerFormat()}
                slotProps={{
                  textField: { size: 'small' },
                  openPickerButton: {
                    disableRipple: true,
                  },
                }}
                maxDate={datePickerMaxDate ? new Date(datePickerMaxDate) : null}
              />
            </div>
          </RadioGroup>
          <div className={styles.actionButtons}>
            <Button variant="outlined" onClick={closeModal}>
              {t('general.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={
                (deactivationMethod === DeactivationMethod.deactivateOnDate &&
                  (!inactiveDate || !isDateValid)) ||
                disableSubmit
              }
            >
              {deactivationMethod === DeactivationMethod.discontinue
                ? t('subscriptions.discontinue')
                : t('subscriptions.deactivate')}
            </Button>
          </div>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default DiscontinueModal;
