import Dialog from '@standardsdigital/ui-components/Dialog';
import IconButton from '@standardsdigital/ui-components/IconButton';
import Table, {
  VisibilityState,
  createColumnHelper,
} from '@standardsdigital/ui-components/Table';
import TextInput from '@standardsdigital/ui-components/TextInput';
import Expand from '@standardsdigital/ui-components/Expand';
import Button from '@standardsdigital/ui-components/Button';
import Icon from '@standardsdigital/ui-components/Icon';
import Select from '@standardsdigital/ui-components/Select';
import SelectItem from '@standardsdigital/ui-components/SelectItem';
import {
  FormEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CannotBeAddedReason,
  EformCount,
  LineSource,
  SearchProductsApiArg,
  useAddProductToSubscriptionMutation,
  useSearchProductsQuery,
} from 'redux/services/api.generated';
import styles from './ProductSearch.module.css';
import { EnhancedProduct, UpdateStatus } from 'types/general';
import Loading from '@standardsdigital/ui-components/Loading';

interface ProductSearchProps {
  subscriptionId: string;
  isActiveSubscription: boolean;
  isEform: boolean;
}

interface Quantity {
  [productId: string]: EformCount;
}

const pageSize = 50;

const columnHelper = createColumnHelper<EnhancedProduct>();

const cannotBeAddedReasons = Object.values(CannotBeAddedReason);

const ProductSearch = ({
  subscriptionId,
  isActiveSubscription,
  isEform,
}: ProductSearchProps) => {
  const { t, i18n } = useTranslation();

  const tableRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState<string | undefined>();

  const [quantity, setQuantity] = useState<Quantity>({});

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
  }, [query, tableRef]);

  let productTypes: SearchProductsApiArg['productTypes'] = [
    'standard',
    'collection',
  ];
  if (isEform) {
    productTypes = ['eform'];
  }

  const searchProducts = useSearchProductsQuery(
    {
      subscriptionId,
      query,
      productTypes,
      page,
      pageSize,
      language: i18n.language,
    },
    { skip: !query },
  );

  const eformCountValues = useMemo(
    () => Object.values(EformCount).filter((value) => typeof value === 'number'),
    [],
  );

  const { totalCount, items, hasNextPage } = useMemo(
    () =>
      searchProducts.data ?? {
        totalCount: 0,
        items: [],
        hasNextPage: false,
      },
    [searchProducts],
  );

  const hasData = items && items.length > 0;
  const [addProduct] = useAddProductToSubscriptionMutation();
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'status',
        header: 'Status',
        size: 80,
        cell: ({
          row: {
            original: { state, cannotBeAddedReason },
          },
        }) => {
          const warningReasons = cannotBeAddedReasons.filter(
            (reason) =>
              ![
                CannotBeAddedReason.AlreadyAdded,
                CannotBeAddedReason.PriceCalculationError,
              ].includes(reason),
          );
          const alreadyAdded =
            state === UpdateStatus.Success ||
            cannotBeAddedReason === CannotBeAddedReason.AlreadyAdded;
          const priceCalculationError =
            cannotBeAddedReason === CannotBeAddedReason.PriceCalculationError;
          const updateError = state === UpdateStatus.Error;

          if (alreadyAdded) {
            return <Icon name="Check" />;
          }

          if (priceCalculationError || updateError) {
            return <Icon name="XOctagon" className={styles.addProductError} />;
          }

          if (cannotBeAddedReason && warningReasons.includes(cannotBeAddedReason)) {
            return (
              <Icon name="AlertTriangle" className={styles.addProductWarning} />
            );
          }

          if (state === UpdateStatus.Loading) {
            return <Loading size="small" aria-label={t('general.loading')} />;
          }

          return null;
        },
      }),
      columnHelper.accessor('name', {
        header: t('productSearch.name'),
        size: 200,
      }),
      columnHelper.accessor('title', {
        header: t('productSearch.title'),
        size: 400,
      }),
      columnHelper.accessor('productType', {
        header: t('productSearch.type'),
        size: 100,
      }),
      columnHelper.display({
        id: 'quantity',
        header: t('productSearch.quantity'),
        cell: ({
          row: {
            original: { cannotBeAddedReason, id, state },
          },
        }) => {
          if (
            (cannotBeAddedReason &&
              cannotBeAddedReasons.includes(cannotBeAddedReason)) ||
            typeof id !== 'string'
          ) {
            return null;
          }
          const productId = id ?? '';
          return (
            <Select
              selectSize="small"
              label={t('productSearch.selectQuantity')}
              invisibleLabel
              value={quantity[productId] ?? EformCount.$10}
              onChange={(event) => {
                setQuantity((prev) => ({
                  ...prev,
                  [productId]: Number(event.target.value),
                }));
              }}
              disabled={state === UpdateStatus.Success}
            >
              {eformCountValues.map((value) => {
                return (
                  <SelectItem value={value} key={value}>
                    {value}
                  </SelectItem>
                );
              })}
            </Select>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: t('productSearch.actions'),
        size: 380,
        cell: ({
          row: {
            original: {
              cannotBeAddedReason,
              cannotBeAddedMessage,
              state,
              id,
              productType,
            },
          },
        }) => {
          const addProductSuccess = state === UpdateStatus.Success;
          const cannotBeAdded =
            cannotBeAddedReason &&
            cannotBeAddedReasons.includes(cannotBeAddedReason);
          const isLoading = state === UpdateStatus.Loading;
          const productId = id ?? '';
          const hasErrorMessage =
            cannotBeAddedReason === CannotBeAddedReason.PriceCalculationError &&
            cannotBeAddedMessage;
          if (cannotBeAdded) {
            if (hasErrorMessage) {
              return (
                <Expand
                  triggerButtonSize="small"
                  triggerButtonVariant="text"
                  triggerButtonText={t(
                    `productSearch.cannotBeAddedReason.${cannotBeAddedReason}`,
                  )}
                  content={cannotBeAddedMessage}
                />
              );
            }
            return (
              <>
                {cannotBeAddedReason !== CannotBeAddedReason.AlreadyAdded && (
                  <p>
                    {t(`productSearch.cannotBeAddedReason.${cannotBeAddedReason}`)}
                  </p>
                )}
              </>
            );
          }
          return (
            <div className={styles.actions}>
              <Button
                size="small"
                disabled={addProductSuccess || isLoading}
                onClick={() => {
                  void addProduct({
                    subscriptionId,
                    addProductRequest: {
                      productId: id ?? '',
                      productType,
                      lineSource: LineSource.Manual,
                      eformCount: isEform
                        ? quantity[productId] ?? EformCount.$10
                        : null,
                    },
                    // @ts-ignore We are adding the query in the front end to control state.
                    query,
                  });
                }}
              >
                {t('productSearch.addAsManual')}
              </Button>
              <Button
                size="small"
                disabled={addProductSuccess || isLoading}
                onClick={() => {
                  void addProduct({
                    subscriptionId,
                    addProductRequest: {
                      productId: id ?? '',
                      productType,
                      lineSource: LineSource.Renewal,
                      eformCount: isEform
                        ? quantity[productId] ?? EformCount.$10
                        : null,
                    },
                    // @ts-ignore We are adding the query in the front end to control state.
                    query,
                  });
                }}
              >
                {t('productSearch.addAsRenewal')}
              </Button>
            </div>
          );
        },
      }),
    ],
    [addProduct, eformCountValues, isEform, quantity, query, subscriptionId, t],
  );

  const initialColumnVisibility: VisibilityState = useMemo(
    () => ({
      status: true,
      name: true,
      title: true,
      type: true,
      quantity: isEform,
      actions: true,
    }),
    [isEform],
  );

  const onFetchNextPage = useCallback(() => {
    if (hasNextPage) {
      setPage((prev) => prev + 1);
    }
  }, [setPage, hasNextPage]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    setQuery(formData.get('search')?.toString() ?? '');
    setPage(0);
  };

  const pagination = useMemo(() => {
    return {
      onFetchNextPage,
      totalRowCount: totalCount ?? 0,
    };
  }, [onFetchNextPage, totalCount]);

  const fetching = {
    isFetching: searchProducts.isFetching,
    isFetchingText: t('productSearch.loadingData'),
  };

  return (
    <>
      <IconButton
        icon="Plus"
        onClick={() => setOpen(true)}
        disabled={!isActiveSubscription}
      >
        {t('productSearch.addProduct')}
      </IconButton>
      <Dialog
        title={`${t('productSearch.addProductTitle', { subscriptionId })} ${
          isEform ? ` (${t('labels.eforms')})` : ''
        }`}
        open={open}
        onOpenChange={setOpen}
        closeButtonText={t('general.close')}
        className={styles.dialog}
        // Disable modal to avoid performance issues (see ticket CL-76).
        modal={false}
      >
        <form
          onSubmit={handleSubmit}
          className={styles.form}
          aria-labelledby="search-field"
        >
          <TextInput
            id="search-field"
            label={t('productSearch.searchLabel')}
            name="search"
          />
          <Button type="submit" variant="primary">
            {t('general.search')}
          </Button>
        </form>
        {typeof query === 'string' && !searchProducts.isLoading && (
          <p className={styles.count}>
            {t('productSearch.totalProducts', {
              totalCount,
            })}
          </p>
        )}
        {hasData && (
          <Table<EnhancedProduct>
            columns={columns}
            data={searchProducts.data?.items ?? []}
            pagination={pagination}
            tableHeight="var(--table-height)"
            fetching={fetching}
            className={styles.table}
            aria-label={t('general.productSearch')}
            tableRef={tableRef as MutableRefObject<HTMLDivElement>}
            initialColumnVisibility={initialColumnVisibility}
          />
        )}
      </Dialog>
    </>
  );
};

export default ProductSearch;
