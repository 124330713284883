import { Typography, Button } from '@mui/joy';
import { FileXIcon, FrownIcon, LinkIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './NotFoundPage.module.css';

const DashlineSvg: React.FC = () => (
  <svg
    className={styles.dashline}
    width="2.25rem"
    height="0.5rem"
    stroke="currentColor"
    viewBox="0 0 36 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M4 4H32"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeDasharray="0.1 8"
    />
  </svg>
);

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <main className={styles.notFoundPage}>
      <div className={styles.visual} aria-hidden>
        <LinkIcon className={styles.icon} size="3em" />
        <DashlineSvg />
        <FileXIcon className={styles.iconShake} size="5em" />
        <DashlineSvg />
        <FrownIcon className={styles.icon} size="3em" />
      </div>
      <Typography level="heading1" component="h1" className={styles.title}>
        {t('notFound.title')}
      </Typography>
      <Typography level="bodyXl" className={styles.text}>
        {t('notFound.text')}
      </Typography>
      <Button component={Link} to="/">
        {t('notFound.action')}
      </Button>
    </main>
  );
};

export default NotFoundPage;
