import i18n from 'i18n';
import { format, parseISO } from 'date-fns';
import { dateLocales } from 'util/constants';

/**
 * Function to get the active customer code.
 * @returns {string} - the active customer code.
 */
export const getActiveCustomer = (): string =>
  import.meta.env.REACT_APP_CUSTOMER_CODE || 'sn';

const defaultFormat = 'yyyy-MM-dd';

/**
 * Function to get the current language from i18n.
 * @returns {string} - a lowercased two-letter language code.
 */
const getCurrentLanguage = () =>
  i18n.language.toUpperCase() as keyof typeof dateLocales;

/**
 * Function to get the site's configured date format.
 * @returns {string} - the date format.
 */
export const getDateFormat = (): string => {
  try {
    const formats = JSON.parse(import.meta.env.REACT_APP_DATE_FORMATS) as Record<
      string,
      string
    >;
    if (!formats[getCurrentLanguage()]) {
      return defaultFormat;
    }

    return formats[getCurrentLanguage()];
  } catch {
    return defaultFormat;
  }
};

/**
 * Function to get the date format for date pickers.
 * @returns {string} - the date format.
 */
export const getDatePickerFormat = () => {
  const validDateFormat = /^((y{4}|M{2}|d{2})([-./, ]|$)){3}$/;
  const formatIsValid = validDateFormat.test(getDateFormat());
  return formatIsValid ? getDateFormat() : defaultFormat;
};

/**
 * Function to format ISO date strings.
 * @param {string} date - the ISO date string to format.
 * @returns {string} - the formatted date.
 */
export const formatDate = (date: string | Date | undefined) => {
  if (!date) {
    return '';
  }
  const isoDate = typeof date === 'string' ? parseISO(date) : date;
  return format(isoDate, getDateFormat(), {
    locale: dateLocales[getCurrentLanguage()],
  });
};
