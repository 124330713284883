import { BrowserRouter } from 'react-router-dom';
import Favicon from '../util/Favicon/Favicon';
import Header from '../global/Header/Header';
import LoginHandler from '../util/LoginHandler/LoginHandler';
import 'themes/baseTheme/styles/global-styles.css';
import styles from './App.module.css';
import Message from '../global/Message/Message';
import { Route, Routes } from 'react-router-dom';
import Subscriptions from 'components/pages/Subscriptions/Subscriptions';
import SubscriptionLines from 'components/pages/SubscriptionLines/SubscriptionLines';
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage';
import ThemeProvider from '@standardsdigital/ui-components/ThemeProvider';

const App = () => {
  return (
    <BrowserRouter>
      <Favicon />
      <ThemeProvider theme="sn" />
      <div className={styles.pageWrapper}>
        <Header />
        <LoginHandler>
          <Routes>
            <Route path="/" element={<Subscriptions />} />
            <Route
              path="/subscriptions/:subscriptionId/*"
              element={<SubscriptionLines />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LoginHandler>
        <Message />
      </div>
    </BrowserRouter>
  );
};

export default App;
