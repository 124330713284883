import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { Button, Checkbox, Menu, MenuItem } from '@mui/joy';
import { SubscriptionModel } from 'redux/services/api.generated';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDown, XIcon } from 'lucide-react';
import styles from './SubscriptionFilters.module.css';
import {
  initialSubscriptionStatuses,
  SubscriptionStatuses,
} from 'components/pages/Subscriptions/SubscriptionList/SubscriptionList';

interface SubscriptionFiltersProps {
  statuses: SubscriptionStatuses;
  setStatuses: Dispatch<SetStateAction<SubscriptionStatuses>>;
}

const SubscriptionFilters = ({
  statuses,
  setStatuses,
}: SubscriptionFiltersProps) => {
  const { t } = useTranslation();

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const selectListOpen = Boolean(anchorElement);

  const toggleStatus = (key: string) => {
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [key]: !prevStatuses[key as keyof typeof prevStatuses],
    }));
  };

  return (
    <>
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement> | null) =>
          event && setAnchorElement(event.currentTarget)
        }
        size="sm"
        variant="outlined"
        endDecorator={<ChevronDown />}
        className={anchorElement ? styles.disabledButton : ''}
      >
        {t('labels.status')}
      </Button>
      <Menu
        anchorEl={anchorElement}
        open={selectListOpen}
        onClose={() => setAnchorElement(null)}
        role="listbox"
        className={styles.menu}
      >
        <MenuItem
          className={styles.resetStatuses}
          onClick={() => setStatuses(initialSubscriptionStatuses)}
        >
          <XIcon size="1.14285714em" className={styles.xIcon} />
          {t('general.resetStatuses')}
        </MenuItem>
        {Object.entries(statuses).map(([key, value]) => (
          <MenuItem
            key={key}
            role="option"
            onClick={() => toggleStatus(key)}
            className={styles.menuItem}
          >
            <Checkbox
              checkedIcon={<CheckIcon strokeWidth={3} size="0.9375em" />}
              checked={value || false}
              label={t(
                `subscriptionStatuses.${
                  key as SubscriptionModel['subscriptionStatus']
                }`,
              )}
              size="sm"
              overlay
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SubscriptionFilters;
