import styles from './StatusIndicator.module.css';
import { Sheet, SheetProps } from '@mui/joy';

export interface StatusIndicatorProps {
  color: SheetProps['color'];
  children?: string;
  className?: string;
  type?: 'subscription' | 'subscriptionLine';
}

const StatusIndicator = ({
  color,
  children,
  className,
  type = 'subscription',
}: StatusIndicatorProps) => {
  let classes = styles[type];
  if (className) {
    classes += ` ${className}`;
  }

  if (!children) {
    return null;
  }

  return (
    <Sheet color={color} component={'span'} variant="soft" className={classes}>
      {children}
    </Sheet>
  );
};

export default StatusIndicator;
