import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import styles from './FindSubscribers.module.css';
import { Button, Input, Typography } from '@mui/joy';
import { SubscriberModel } from 'redux/services/api.generated';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { XIcon } from 'lucide-react';
import SubscriberList from 'components/pages/Subscriptions/SubscriberList/SubscriberList';

interface FindSubscribersProps {
  setSelectedSubscriber: Dispatch<SetStateAction<SubscriberModel | undefined>>;
  selectedSubscriber: SubscriberModel | undefined;
  className?: string;
}

const FindSubscribers = ({
  setSelectedSubscriber,
  selectedSubscriber,
  className,
}: FindSubscribersProps) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(searchParams.get('q') || '');
  const [value, setValue] = useState(searchParams.get('q'));
  const [page, setPage] = useState(1);

  useEffect(() => {
    setSearchParams({ q: value || '' });
  }, [setSearchParams, value]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValue(inputValue);
    setPage(1);
    setSelectedSubscriber(undefined);
  };

  return (
    <article className={`${styles.findSubscribers} ${className || ''}`}>
      <Typography level="heading2" component="h2" className={styles.heading}>
        {t('subscribers.subscribers')}
      </Typography>
      <Typography
        level="labelMd"
        component="label"
        htmlFor="subscribers"
        id="filter-subscribers-label"
      >
        {t('subscribers.filterSubscribers')}
      </Typography>
      <form
        onSubmit={handleSubmit}
        className={styles.form}
        aria-labelledby="filter-subscribers-label"
      >
        <Input
          placeholder={t('subscribers.organizationNameOrId')}
          variant="outlined"
          name="subscribers"
          id="subscribers"
          fullWidth
          onChange={(event) => setInputValue(event.target.value)}
          value={inputValue}
          className={styles.searchInput}
          endDecorator={
            <Button
              variant="plain"
              color="neutral"
              aria-label={t('general.clearSearch')}
              onClick={() => {
                setValue('');
                setInputValue('');
              }}
              className={styles.clearSearchButton}
            >
              <XIcon aria-hidden />
            </Button>
          }
        />
        <Button type="submit">{t('general.search')}</Button>
      </form>
      <SubscriberList
        selectedSubscriber={selectedSubscriber}
        setSelectedSubscriber={setSelectedSubscriber}
        page={page}
        setPage={setPage}
        inputValue={value}
      />
    </article>
  );
};

export default FindSubscribers;
