import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TitleState {
  translationKey?: string;
  options?: Record<string, string>;
}

interface AppState {
  appTitle: TitleState;
}

const initialState: AppState = {
  appTitle: {},
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppTitle(state, action: PayloadAction<TitleState>) {
      state.appTitle = action.payload;
    },
  },
});

export const {
  actions: { setAppTitle },
  reducer: appReducer,
} = appSlice;
