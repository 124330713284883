import { ProductModel } from 'redux/services/api.generated';

export type UserClaim = {
  type: string;
  value: string;
};

export enum DocumentLanguage {
  English = 'en',
  Norwegian = 'no',
  Spanish = 'es',
  French = 'fr',
  German = 'de',
  Italian = 'it',
  Danish = 'da',
  Russian = 'ru',
  Swedish = 'sv',
  Portugese = 'pt',
  Arabic = 'ar',
  Chinese = 'zh',
}

export enum UpdateStatus {
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export type EnhancedProduct = ProductModel & {
  state?: UpdateStatus;
};
