import { Button, Modal, ModalDialog, Typography } from '@mui/joy';
import { Dispatch, SetStateAction } from 'react';
import styles from './ReactivationModal.module.css';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'redux/slices/messageSlice';
import { useBulkReactivateSubscriptionLinesMutation } from 'redux/services/api.generated';
import { useDispatch } from 'react-redux';

interface ReactivationModalProps {
  warningMessage: string;
  open: boolean;
  setModalState: Dispatch<SetStateAction<{ open: boolean; warningMessage: string }>>;
  itemsToReactivate: number[];
  refetch: () => void;
}

const ReactivationModal = ({
  warningMessage,
  open,
  setModalState,
  itemsToReactivate,
  refetch,
}: ReactivationModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [reactivateSubscriptionLines] = useBulkReactivateSubscriptionLinesMutation();

  const closeModal = () => setModalState({ open: false, warningMessage: '' });

  const handleConfirm = () => {
    setModalState({ open: false, warningMessage: '' });
    reactivateSubscriptionLines({
      bulkReactivateSubscriptionLinesCommand: {
        subscriptionLineIds: itemsToReactivate,
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(
          showMessage({
            text: t('subscriptions.successfullyReactivated'),
            color: 'success',
          }),
        );
      })
      .catch(() => {
        // Errors are handled in middleware.
      });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={styles.modalContainer}
    >
      <ModalDialog variant="plain" className={styles.modal}>
        <Typography
          level="heading4"
          component="h2"
          id="modal-title"
          className={styles.title}
        >
          {t('subscriptions.reactivationStatus')}
        </Typography>
        <Typography id="modal-description" color="danger">
          {warningMessage}
        </Typography>
        <div className={styles.buttons}>
          {itemsToReactivate.length > 0 ? (
            <>
              <Button variant="outlined" color="neutral" onClick={closeModal}>
                {t('general.cancel')}
              </Button>
              <Button onClick={handleConfirm}>{t('general.confirm')}</Button>
            </>
          ) : (
            <Button variant="outlined" color="neutral" onClick={closeModal}>
              {t('general.close')}
            </Button>
          )}
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default ReactivationModal;
