import { Button, Typography } from '@mui/joy';
import DataList from 'components/global/DataList/DataList';
import DataListItem from 'components/global/DataListItem/DataListItem';
import { DocumentLanguage } from 'types/general';
import { formatDate } from '../../../../../util/util';
import styles from './SubscriptionItem.module.css';
import { useTranslation } from 'react-i18next';
import {
  CalendarOffIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  RecycleIcon,
} from 'lucide-react';
import { useState } from 'react';
import {
  SubscriptionModel,
  useReactivateSubscriptionMutation,
} from 'redux/services/api.generated';
import { useAppDispatch } from 'redux/hooks';
import { showMessage } from 'redux/slices/messageSlice';
import StatusIndicator, {
  StatusIndicatorProps,
} from '../../../../global/StatusIndicator/StatusIndicator';
import { downloadFile, getSubscriptionExportUrl } from 'util/fileUtil';
import { Link } from 'react-router-dom';
import EndSubscriptionModal from 'components/pages/Subscriptions/SubscriptionList/EndSubscriptionModal/EndSubscriptionModal';
import Spinner from 'components/global/Spinner/Spinner';
import NoteModal from './NoteModal/NoteModal';
import { buttonIconSize } from 'util/constants';

interface SubscriptionItemProps {
  subscription: SubscriptionModel;
  refetchSubscriptions: () => void;
  isFetching: boolean;
  index: number;
}

const SubscriptionItem = ({
  refetchSubscriptions,
  isFetching,
  subscription,
  index,
}: SubscriptionItemProps) => {
  const {
    subscriptionId,
    description,
    serviceLevel,
    agreementPeriodEndDate,
    invoicePeriodEndDate,
    languages,
    subscriptionLineCount,
    userCount,
    subscriptionStatus,
    inactiveDate,
    contractDate,
    note,
  } = subscription;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [discontinueModalOpen, setDiscontinueModalOpen] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  const [reactivateSubscription] = useReactivateSubscriptionMutation();

  const headingId = `${subscriptionId}-heading`;

  const documentLanguages = languages
    .map((lang) => t(`documentLanguages.${lang as DocumentLanguage}`))
    .join(', ');

  const isFutureDiscontinued =
    subscriptionStatus === 'Discontinued' && inactiveDate === invoicePeriodEndDate;

  const getStatusLabel = () => {
    const status = t(`subscriptionStatuses.${subscriptionStatus}`);
    if (subscriptionStatus === 'PendingActive') {
      return `${subscriptionStatus} ${contractDate ? formatDate(contractDate) : ''}`;
    }
    if (subscriptionStatus === 'Discontinued') {
      return t('subscriptions.discontinuedFrom', { date: formatDate(inactiveDate) });
    }
    if (inactiveDate) {
      return `${subscriptionStatus} ${formatDate(inactiveDate)}`;
    }
    return status;
  };

  const shouldShowReactivateButton =
    ['Inactive', 'PendingInactive'].includes(subscriptionStatus) ||
    isFutureDiscontinued;

  const shouldShowEndSubscriptionButton = subscriptionStatus === 'Active';

  const viewSubscriptionLinesText = () => {
    if (subscriptionLineCount === 0) {
      return t('subscriptions.noSubscriptionLines');
    }
    if (subscriptionLineCount === 1) {
      return t('subscriptions.viewSubscriptionLines.singular', {
        subscriptionLineCount,
      });
    }
    return t('subscriptions.viewSubscriptionLines.plural', {
      subscriptionLineCount,
    });
  };

  const handleReactivate = () => {
    reactivateSubscription({
      subscriptionId,
      isPendingInactive: subscriptionStatus === 'PendingInactive',
    })
      .unwrap()
      .then(() => {
        refetchSubscriptions();
        dispatch(
          showMessage({
            text: t('subscriptions.subscriptionHasBeenReactivated', {
              subscriptionId,
            }),
            color: 'success',
          }),
        );
      })
      .catch(() => {
        // Errors are handled in middleware.
      });
  };

  const handleExport = async () => {
    setIsDownloadingFile(true);
    const result = await downloadFile(
      getSubscriptionExportUrl(subscriptionId),
      `${subscriptionId}.xlsx`,
    );

    if (!result) {
      dispatch(
        showMessage({
          text: t('subscriptions.exportError'),
          color: 'danger',
        }),
      );
    }
    setIsDownloadingFile(false);
  };

  let statusColor: StatusIndicatorProps['color'] = 'neutral';
  switch (subscriptionStatus) {
    case 'Active':
      statusColor = 'success';
      break;
    case 'PendingInactive':
    case 'PendingActive':
    case 'Discontinued':
      statusColor = 'warning';
      break;
    case 'Inactive':
    case 'Expired':
      statusColor = 'danger';
      break;
  }

  let subscriptionStyles = styles.subscription;
  if (discontinueModalOpen) {
    subscriptionStyles += ` ${styles.noFocus}`;
  }
  if (isFetching) {
    subscriptionStyles += ` ${styles.isFetching}`;
  }

  const getSubscriptionLink = () => {
    if (subscriptionLineCount === 0 || isFetching) {
      return '';
    }
    return `/subscriptions/${subscriptionId}`;
  };

  return (
    <li
      key={subscriptionId}
      className={subscriptionStyles}
      tabIndex={index === 0 ? 0 : -1}
    >
      <EndSubscriptionModal
        isOpen={discontinueModalOpen}
        setIsOpen={setDiscontinueModalOpen}
        subscriptionId={subscriptionId}
        invoicePeriodEndDate={invoicePeriodEndDate}
        refetchSubscriptions={refetchSubscriptions}
        datePickerMaxDate={agreementPeriodEndDate}
      />
      <NoteModal
        isOpen={isNoteModalOpen}
        setIsOpen={setIsNoteModalOpen}
        note={note}
        subscriptionId={subscriptionId}
        refetchSubscriptions={refetchSubscriptions}
      />
      <article className={styles.cardInner} aria-labelledby={headingId}>
        <header>
          <Typography level="heading4" component="h3" id={headingId}>
            {subscriptionId} {description}
          </Typography>
        </header>
        <StatusIndicator color={statusColor}>{getStatusLabel()}</StatusIndicator>
        <DataList className={styles.dataList}>
          {documentLanguages && (
            <DataListItem label={t('labels.languages')} value={documentLanguages} />
          )}
          {serviceLevel && (
            <DataListItem label={t('labels.services')} value={serviceLevel} />
          )}
          <DataListItem label={t('labels.users')} value={userCount} />
          <DataListItem
            label={t('labels.contractDate')}
            value={formatDate(contractDate)}
          />
          {note && (
            <DataListItem label={t('note.internalNote')} value={note} fullWidth />
          )}
        </DataList>
        <footer className={styles.footer}>
          {shouldShowReactivateButton && (
            <Button
              variant="outlined"
              size="sm"
              startDecorator={<RecycleIcon size={buttonIconSize} />}
              onClick={handleReactivate}
              disabled={isFetching}
            >
              {t('subscriptions.reactivate')}
            </Button>
          )}
          {shouldShowEndSubscriptionButton && (
            <Button
              startDecorator={<CalendarOffIcon size={buttonIconSize} />}
              variant="outlined"
              size="sm"
              onClick={() => setDiscontinueModalOpen(true)}
              disabled={isFetching}
            >
              {t('subscriptions.endSubscription')}
            </Button>
          )}
          <Button
            variant="outlined"
            size="sm"
            startDecorator={
              isDownloadingFile ? (
                <Spinner size="xs" />
              ) : (
                <DownloadIcon size={buttonIconSize} />
              )
            }
            disabled={isDownloadingFile || isFetching}
            onClick={() => void handleExport()}
            className={styles.downloadButton}
          >
            {t('general.export')}
          </Button>
          <Button
            onClick={() => setIsNoteModalOpen(true)}
            variant="outlined"
            startDecorator={<EditIcon size={buttonIconSize} />}
            disabled={isFetching}
          >
            {note ? t('note.editNote') : t('note.createNote')}
          </Button>
          <Button
            className={`${styles.viewButton}${
              getSubscriptionLink() ? '' : ' Joy-disabled'
            }`}
            size="sm"
            startDecorator={
              subscriptionLineCount > 0 && <EyeIcon size={buttonIconSize} />
            }
            component={Link}
            to={getSubscriptionLink()}
          >
            {viewSubscriptionLinesText()}
          </Button>
        </footer>
      </article>
    </li>
  );
};

export default SubscriptionItem;
