import { Typography, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import styles from './NotLoggedInMessage.module.css';

const NotLoggedInMessage = () => {
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      <Typography level="heading1" component="h1">
        {t('authentication.pleaseLogIn')}
      </Typography>
      <Typography level="bodyXl" component="p" className={styles.notLoggedInMessage}>
        {t('authentication.notLoggedInMessage')}
      </Typography>
      <Button component="a" href="/bff/login" size="lg">
        {t('authentication.logIn')}
      </Button>
    </main>
  );
};

export default NotLoggedInMessage;
