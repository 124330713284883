import DiscontinueModal, {
  DeactivationMethod,
} from 'components/global/DiscontinueModal/DiscontinueModal';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CollectionModel,
  StandardModel,
  useBulkDeactivateSubscriptionLinesMutation,
  useBulkDiscontinueSubscriptionLinesMutation,
} from 'redux/services/api.generated';
import { showMessage } from 'redux/slices/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { formatDate } from '../../../../util/util';
import { formatISO } from 'date-fns';

interface DiscontinueSubscriptionLineModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  subscriptionLines: Array<{
    id: number;
    name: string;
    status: StandardModel['lineStatus'] | CollectionModel['lineStatus'];
  }>;
  refetch: () => void;
  datePickerMaxDate?: string;
}

const DiscontinueSubscriptionLineModal = ({
  isOpen,
  setIsOpen,
  subscriptionLines,
  refetch,
  datePickerMaxDate,
}: DiscontinueSubscriptionLineModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [discontinueSubscriptionLines] =
    useBulkDiscontinueSubscriptionLinesMutation();
  const [deactivateSubscriptionLines] = useBulkDeactivateSubscriptionLinesMutation();

  const handleSubmit = (
    event: FormEvent<HTMLFormElement>,
    inactiveDate: Date | null,
    closeModal: () => void,
    deactivationMethod: DeactivationMethod,
  ) => {
    event.preventDefault();
    closeModal();

    const subscriptionLineIds = subscriptionLines.map(({ id }) => id);

    if (deactivationMethod === DeactivationMethod.discontinue) {
      discontinueSubscriptionLines({
        bulkDiscontinueSubscriptionLinesCommand: {
          subscriptionLineIds,
        },
      })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(
            showMessage({
              text: t('subscriptions.productsSuccessfullyDiscontinued'),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
    if (deactivationMethod === DeactivationMethod.deactivateToday) {
      deactivateSubscriptionLines({
        bulkDeactivateSubscriptionLinesCommand: {
          subscriptionLineIds,
        },
      })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(
            showMessage({
              text: t('subscriptions.productsSuccessfullyDeactivated'),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
    if (deactivationMethod === DeactivationMethod.deactivateOnDate) {
      deactivateSubscriptionLines({
        bulkDeactivateSubscriptionLinesCommand: {
          subscriptionLineIds,
          inactiveDate: inactiveDate
            ? formatDate(formatISO(inactiveDate))
            : undefined,
        },
      })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(
            showMessage({
              text: t('subscriptions.productsSuccessfullyDeactivated'),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
  };

  let title = t('subscriptions.discontinueSubscriptionLine.plural');
  if (subscriptionLines?.length === 1 && subscriptionLines[0].name) {
    title = `${t('subscriptions.discontinueSubscriptionLine.singular')} ${
      subscriptionLines[0].name
    }`;
  }

  return (
    <DiscontinueModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit}
      title={title}
      disableSubmit={!subscriptionLines?.length}
      datePickerMaxDate={datePickerMaxDate}
      description={t(
        `subscriptions.whenDeactivateSubscriptionLines.${
          subscriptionLines.length === 1 ? 'singular' : 'plural'
        }`,
      )}
    />
  );
};

export default DiscontinueSubscriptionLineModal;
