import {
  DataGridPro as MuiDataGrid,
  GridColDef,
  nbNO,
  GridRowParams,
  GridFilterModel,
  GridColumnMenuProps,
  GridColumnMenu,
} from '@mui/x-data-grid-pro';
import styles from './SubscriptionLinesDataGrid.module.css';
import {
  CollectionModel,
  EformModel,
  StandardModel,
  SubscriptionModel,
} from 'redux/services/api.generated';
import Toolbar from './Toolbar/Toolbar';
import i18n from 'i18n';
import { Dispatch, SetStateAction } from 'react';
import Spinner from 'components/global/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

interface DataGridProps {
  rows: StandardModel[] | CollectionModel[] | undefined;
  columns: GridColDef[];
  enableSearch?: boolean;
  includeStandardsInCollections?: boolean;
  setIncludeStandardsInCollections?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  refetch: () => void;
  filterModel: GridFilterModel;
  setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
  datePickerMaxDate?: string;
  subscriptionStatus?: SubscriptionModel['subscriptionStatus'];
  name: 'standards' | 'collections' | 'eforms';
  isFetching: boolean;
}

const SubscriptionLinesDataGrid = ({
  rows,
  columns,
  enableSearch,
  includeStandardsInCollections,
  setIncludeStandardsInCollections,
  className,
  refetch,
  filterModel,
  setFilterModel,
  datePickerMaxDate,
  subscriptionStatus,
  name,
  isFetching,
}: DataGridProps) => {
  const { t } = useTranslation();

  const norwegianLocale = {
    ...nbNO.components.MuiDataGrid.defaultProps.localeText,
    // See list of translation strings available at https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts.
    // TODO: Remove when translation strings are updated in @mui-x.
    columnMenuManageColumns: 'Administrer kolonner',
  };

  let dataGridStyles = styles.dataGrid;
  if (className) {
    dataGridStyles += ` ${className}`;
  }

  // TODO: Improve handling of missing subscriptionStatus (SM-146).
  if (!rows || !subscriptionStatus) {
    return <Spinner />;
  }
  const isEditable = !['Inactive', 'Discontinued'].includes(subscriptionStatus);

  const CustomColumnMenu = (props: GridColumnMenuProps) => (
    <GridColumnMenu
      {...props}
      slots={{
        // Remove user's ability to manage columns.
        columnMenuColumnsItem: null,
      }}
    />
  );

  return (
    <MuiDataGrid
      columns={columns}
      rows={rows}
      checkboxSelection={isEditable}
      disableRowSelectionOnClick
      isRowSelectable={(
        params: GridRowParams<StandardModel | CollectionModel | EformModel>,
      ) =>
        ['Standard', 'Collection', 'Eform'].includes(params.row.lineType) &&
        params.row.lineStatus !== 'Draft'
      }
      slots={{ toolbar: Toolbar, columnMenu: CustomColumnMenu }}
      slotProps={{
        toolbar: {
          enableSearch,
          includeStandardsInCollections,
          setIncludeStandardsInCollections,
          refetch,
          datePickerMaxDate,
        },
      }}
      className={dataGridStyles}
      density="compact"
      localeText={i18n.language === 'no' ? norwegianLocale : undefined}
      filterModel={filterModel}
      onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
      aria-label={t(`labels.${name}`)}
      loading={isFetching}
    />
  );
};

export default SubscriptionLinesDataGrid;
