import { api } from './api.empty';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addProductToSubscription: build.mutation<
      AddProductToSubscriptionApiResponse,
      AddProductToSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/products`,
        method: 'POST',
        body: queryArg.addProductRequest,
      }),
    }),
    deactivateSubscription: build.mutation<
      DeactivateSubscriptionApiResponse,
      DeactivateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/deactivate`,
        method: 'POST',
        params: { inactiveDate: queryArg.inactiveDate },
      }),
    }),
    deleteSubscriptionNote: build.mutation<
      DeleteSubscriptionNoteApiResponse,
      DeleteSubscriptionNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/note`,
        method: 'DELETE',
      }),
    }),
    updateSubscriptionNote: build.mutation<
      UpdateSubscriptionNoteApiResponse,
      UpdateSubscriptionNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/note`,
        method: 'PUT',
        body: queryArg.updateNoteRequest,
      }),
    }),
    discontinueSubscription: build.mutation<
      DiscontinueSubscriptionApiResponse,
      DiscontinueSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/discontinue`,
        method: 'POST',
      }),
    }),
    exportSubscription: build.mutation<
      ExportSubscriptionApiResponse,
      ExportSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/export`,
        method: 'POST',
      }),
    }),
    getSubscription: build.query<GetSubscriptionApiResponse, GetSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}`,
      }),
    }),
    listSubscriptionCollections: build.query<
      ListSubscriptionCollectionsApiResponse,
      ListSubscriptionCollectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/collections`,
      }),
    }),
    listSubscriptionEforms: build.query<
      ListSubscriptionEformsApiResponse,
      ListSubscriptionEformsApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/eforms`,
      }),
    }),
    listSubscriptions: build.query<
      ListSubscriptionsApiResponse,
      ListSubscriptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions`,
        params: { subscriberId: queryArg.subscriberId },
      }),
    }),
    listSubscriptionStandards: build.query<
      ListSubscriptionStandardsApiResponse,
      ListSubscriptionStandardsApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/standards`,
        params: {
          includeStandardsInCollections: queryArg.includeStandardsInCollections,
        },
      }),
    }),
    reactivateSubscription: build.mutation<
      ReactivateSubscriptionApiResponse,
      ReactivateSubscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/reactivate`,
        method: 'POST',
        params: { isPendingInactive: queryArg.isPendingInactive },
      }),
    }),
    searchProducts: build.query<SearchProductsApiResponse, SearchProductsApiArg>({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.subscriptionId}/search`,
        params: {
          query: queryArg.query,
          productTypes: queryArg.productTypes,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          language: queryArg.language,
        },
      }),
    }),
    bulkDeactivateSubscriptionLines: build.mutation<
      BulkDeactivateSubscriptionLinesApiResponse,
      BulkDeactivateSubscriptionLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/deactivate`,
        method: 'POST',
        body: queryArg.bulkDeactivateSubscriptionLinesCommand,
      }),
    }),
    bulkDiscontinueSubscriptionLines: build.mutation<
      BulkDiscontinueSubscriptionLinesApiResponse,
      BulkDiscontinueSubscriptionLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/discontinue`,
        method: 'POST',
        body: queryArg.bulkDiscontinueSubscriptionLinesCommand,
      }),
    }),
    bulkReactivateSubscriptionLines: build.mutation<
      BulkReactivateSubscriptionLinesApiResponse,
      BulkReactivateSubscriptionLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/reactivate`,
        method: 'POST',
        body: queryArg.bulkReactivateSubscriptionLinesCommand,
      }),
    }),
    deactivateSubscriptionLine: build.mutation<
      DeactivateSubscriptionLineApiResponse,
      DeactivateSubscriptionLineApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/${queryArg.subscriptionLineId}/deactivate`,
        method: 'POST',
        params: { inactiveDate: queryArg.inactiveDate },
      }),
    }),
    discontinueSubscriptionLine: build.mutation<
      DiscontinueSubscriptionLineApiResponse,
      DiscontinueSubscriptionLineApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/${queryArg.subscriptionLineId}/discontinue`,
        method: 'POST',
      }),
    }),
    exportSubscriptionLines: build.mutation<
      ExportSubscriptionLinesApiResponse,
      ExportSubscriptionLinesApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/export`,
        method: 'POST',
        body: queryArg.exportSubscriptionLinesCommand,
      }),
    }),
    reactivateSubscriptionLine: build.mutation<
      ReactivateSubscriptionLineApiResponse,
      ReactivateSubscriptionLineApiArg
    >({
      query: (queryArg) => ({
        url: `/subscription-lines/${queryArg.subscriptionLineId}/reactivate`,
        method: 'POST',
      }),
    }),
    searchSubscribers: build.query<
      SearchSubscribersApiResponse,
      SearchSubscribersApiArg
    >({
      query: (queryArg) => ({
        url: `/subscribers/search`,
        params: {
          query: queryArg.query,
          page: queryArg.page,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type AddProductToSubscriptionApiResponse = /** status 200 Success */ void;
export type AddProductToSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** Request body */
  addProductRequest: AddProductRequest;
};
export type DeactivateSubscriptionApiResponse = /** status 200 Success */ void;
export type DeactivateSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** In format 'yyyy-MM-dd' */
  inactiveDate?: string | null;
};
export type DeleteSubscriptionNoteApiResponse = /** status 200 Success */ void;
export type DeleteSubscriptionNoteApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type UpdateSubscriptionNoteApiResponse = /** status 200 Success */ void;
export type UpdateSubscriptionNoteApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** Request body */
  updateNoteRequest: UpdateNoteRequest;
};
export type DiscontinueSubscriptionApiResponse = /** status 200 Success */ void;
export type DiscontinueSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type ExportSubscriptionApiResponse = /** status 200 Success */ void;
export type ExportSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type GetSubscriptionApiResponse =
  /** status 200 Subscription model */ SubscriptionModel;
export type GetSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type ListSubscriptionCollectionsApiResponse =
  /** status 200 List of subscription lines */ CollectionModel[];
export type ListSubscriptionCollectionsApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type ListSubscriptionEformsApiResponse =
  /** status 200 List of subscription lines */ EformModel[];
export type ListSubscriptionEformsApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
};
export type ListSubscriptionsApiResponse =
  /** status 200 List of subscriptions for the customer */ SubscriptionListModel[];
export type ListSubscriptionsApiArg = {
  /** Subscriber identifier */
  subscriberId: string;
};
export type ListSubscriptionStandardsApiResponse =
  /** status 200 List of subscription lines */ StandardModel[];
export type ListSubscriptionStandardsApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** Should include standards in collections */
  includeStandardsInCollections?: boolean;
};
export type ReactivateSubscriptionApiResponse = /** status 200 Success */ void;
export type ReactivateSubscriptionApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** Is subscription status pending inactive */
  isPendingInactive?: boolean;
};
export type SearchProductsApiResponse =
  /** status 200 Product search response */ PagedListModelProductModel;
export type SearchProductsApiArg = {
  /** Subscription identifier */
  subscriptionId: string;
  /** Term to search for */
  query?: string;
  /** Product types to search for */
  productTypes?: ('unknown' | 'standard' | 'collection' | 'eform')[];
  /** Page number */
  page?: number;
  /** Page size */
  pageSize?: number;
  /** Language */
  language?: string;
};
export type BulkDeactivateSubscriptionLinesApiResponse =
  /** status 200 Success */ void;
export type BulkDeactivateSubscriptionLinesApiArg = {
  /** Request body */
  bulkDeactivateSubscriptionLinesCommand: BulkDeactivateSubscriptionLinesCommand;
};
export type BulkDiscontinueSubscriptionLinesApiResponse =
  /** status 200 Success */ void;
export type BulkDiscontinueSubscriptionLinesApiArg = {
  /** Request body */
  bulkDiscontinueSubscriptionLinesCommand: BulkDiscontinueSubscriptionLinesCommand;
};
export type BulkReactivateSubscriptionLinesApiResponse =
  /** status 200 Success */ void;
export type BulkReactivateSubscriptionLinesApiArg = {
  /** Request body */
  bulkReactivateSubscriptionLinesCommand: BulkReactivateSubscriptionLinesCommand;
};
export type DeactivateSubscriptionLineApiResponse = /** status 200 Success */ void;
export type DeactivateSubscriptionLineApiArg = {
  /** Subscription line identifier */
  subscriptionLineId: string;
  /** In format 'yyyy-MM-dd' */
  inactiveDate?: string | null;
};
export type DiscontinueSubscriptionLineApiResponse = /** status 200 Success */ void;
export type DiscontinueSubscriptionLineApiArg = {
  /** Subscription line identifier */
  subscriptionLineId: string;
};
export type ExportSubscriptionLinesApiResponse = /** status 200 Success */ void;
export type ExportSubscriptionLinesApiArg = {
  /** Request body */
  exportSubscriptionLinesCommand: ExportSubscriptionLinesCommand;
};
export type ReactivateSubscriptionLineApiResponse = /** status 200 Success */ void;
export type ReactivateSubscriptionLineApiArg = {
  /** Subscription identifier */
  subscriptionLineId: string;
};
export type SearchSubscribersApiResponse =
  /** status 200 Paged list of subscriber items */ PagedListModelSubscriberModel;
export type SearchSubscribersApiArg = {
  /** Term to search for */
  query?: string;
  /** Page number */
  page?: number;
  /** Page size */
  pageSize?: number;
};
export type SdValidationError = {
  identifier?: string;
  errorMessage?: string;
  errorCode?: string;
  severity?: Severity;
};
export type AddProductRequest = {
  productId: string;
  productType: ProductType;
  lineSource: LineSource;
  eformCount?: EformCount | null;
};
export type UpdateNoteRequest = {
  note?: string;
};
export type SubscriptionModel = {
  subscriberId: string;
  customerId: string;
  subscriberName: string;
  contactPersons: string[];
  subscriptionId: string;
  description: string;
  note?: string;
  subscriptionStatus: SubscriptionStatus;
  contractDate?: string;
  agreementPeriodStartDate: string;
  agreementPeriodEndDate?: string;
  invoicePeriodStartDate: string;
  invoicePeriodEndDate?: string;
  inactiveDate?: string;
  serviceLevel?: string;
  documentServices: (
    | 'None'
    | 'Online'
    | 'Download'
    | 'Print'
    | 'HardCopy'
    | 'OnlineReader'
    | 'Eform'
    | 'Book'
    | 'EnhancedSoftware'
    | 'EnhancedCopyPaste'
    | 'MultipleUserCopy'
  )[];
  previousVersions: string;
  draftStandards: boolean;
  redline: boolean;
  languages: string[];
  fallbackLanguage?: string;
  subscriptionLineCount: number;
  userCount: number;
  modifiedDateTime: string;
};
export type CollectionModel = {
  id: number;
  name: string;
  sdo: string;
  dateAdded: string;
  lineStatus: LineStatus;
  lineType: LineType;
  lineSource: LineSource;
  inactiveDate?: string;
  modifiedDateTime: string;
  collectionStandardCount: number;
};
export type EformModel = {
  id: number;
  name: string;
  count: number;
  sdo: string;
  dateAdded: string;
  lineStatus: LineStatus;
  lineType: LineType;
  lineSource: LineSource;
  inactiveDate?: string;
  modifiedDateTime: string;
};
export type SubscriptionListModel = {
  subscriptionId: string;
  description: string;
  note?: string;
  subscriptionStatus: SubscriptionStatus;
  contractDate?: string;
  agreementPeriodStartDate: string;
  agreementPeriodEndDate?: string;
  invoicePeriodStartDate: string;
  invoicePeriodEndDate?: string;
  inactiveDate?: string;
  serviceLevel?: string;
  documentServices: (
    | 'None'
    | 'Online'
    | 'Download'
    | 'Print'
    | 'HardCopy'
    | 'OnlineReader'
    | 'Eform'
    | 'Book'
    | 'EnhancedSoftware'
    | 'EnhancedCopyPaste'
    | 'MultipleUserCopy'
  )[];
  previousVersions: string;
  draftStandards: boolean;
  redline: boolean;
  languages: string[];
  fallbackLanguage?: string;
  subscriptionLineCount: number;
  userCount: number;
  modifiedDateTime: string;
};
export type StandardModel = {
  id: number;
  name: string;
  collectionName?: string;
  sdo: string;
  dateAdded: string;
  lineStatus: LineStatus;
  lineType: LineType;
  lineSource: LineSource;
  inactiveDate?: string;
  modifiedDateTime: string;
};
export type ProductModel = {
  id: string;
  externalId: string;
  reference: string;
  name: string;
  sdo: string;
  title: string;
  productType: ProductType;
  status: string;
  cannotBeAddedReason?: CannotBeAddedReason | null;
  cannotBeAddedMessage?: string;
};
export type PagedListModelProductModel = {
  totalCount: number;
  hasNextPage: boolean;
  items: ProductModel[];
};
export type BulkDeactivateSubscriptionLinesCommand = {
  subscriptionLineIds?: number[];
  inactiveDate?: string | null;
};
export type BulkDiscontinueSubscriptionLinesCommand = {
  subscriptionLineIds?: number[];
};
export type BulkReactivateSubscriptionLinesCommand = {
  subscriptionLineIds?: number[];
};
export type ExportSubscriptionLinesCommand = {
  subscriptionLineIds?: number[];
};
export type SubscriberModel = {
  id: string;
  customerId: string;
  name: string;
  subscriptionCount: number;
};
export type PagedListModelSubscriberModel = {
  totalCount: number;
  hasNextPage: boolean;
  items: SubscriberModel[];
};
export enum Severity {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}
export enum ProductType {
  Unknown = 'Unknown',
  Standard = 'Standard',
  Collection = 'Collection',
  Eform = 'Eform',
}
export enum LineSource {
  Manual = 'Manual',
  Renewal = 'Renewal',
  Ecommerce = 'Ecommerce',
  Replacement = 'Replacement',
  Update = 'Update',
  Quotation = 'Quotation',
}
export enum EformCount {
  $10 = 10,
  $20 = 20,
  $50 = 50,
  $100 = 100,
  $500 = 500,
}
export enum SubscriptionStatus {
  Draft = 'Draft',
  Active = 'Active',
  Inactive = 'Inactive',
  Expired = 'Expired',
  Discontinued = 'Discontinued',
  PendingActive = 'PendingActive',
  PendingInactive = 'PendingInactive',
  Quotation = 'Quotation',
}
export enum LineStatus {
  Draft = 'Draft',
  Active = 'Active',
  Inactive = 'Inactive',
  Expired = 'Expired',
  Discontinued = 'Discontinued',
  PendingActive = 'PendingActive',
  PendingInactive = 'PendingInactive',
  Quotation = 'Quotation',
}
export enum LineType {
  StandardVariant = 'StandardVariant',
  Standard = 'Standard',
  Collection = 'Collection',
  StandardInCollection = 'StandardInCollection',
  Eform = 'Eform',
}
export enum CannotBeAddedReason {
  AlreadyAdded = 'AlreadyAdded',
  SubpriceProductNotFound = 'SubpriceProductNotFound',
  PriceCalculationError = 'PriceCalculationError',
  LanguageMismatch = 'LanguageMismatch',
  StoreProductNotFound = 'StoreProductNotFound',
  HardcopyOnly = 'HardcopyOnly',
  NoAccessToDrafts = 'NoAccessToDrafts',
  NoAccessToPreviousVersions = 'NoAccessToPreviousVersions',
  UnavailableForSubscription = 'UnavailableForSubscription',
}
export const {
  useAddProductToSubscriptionMutation,
  useDeactivateSubscriptionMutation,
  useDeleteSubscriptionNoteMutation,
  useUpdateSubscriptionNoteMutation,
  useDiscontinueSubscriptionMutation,
  useExportSubscriptionMutation,
  useGetSubscriptionQuery,
  useListSubscriptionCollectionsQuery,
  useListSubscriptionEformsQuery,
  useListSubscriptionsQuery,
  useListSubscriptionStandardsQuery,
  useReactivateSubscriptionMutation,
  useSearchProductsQuery,
  useBulkDeactivateSubscriptionLinesMutation,
  useBulkDiscontinueSubscriptionLinesMutation,
  useBulkReactivateSubscriptionLinesMutation,
  useDeactivateSubscriptionLineMutation,
  useDiscontinueSubscriptionLineMutation,
  useExportSubscriptionLinesMutation,
  useReactivateSubscriptionLineMutation,
  useSearchSubscribersQuery,
} = injectedRtkApi;
