import Spinner from 'components/global/Spinner/Spinner';
import { Button, MenuItem, MenuList, Typography } from '@mui/joy';
import { generatedApi, SubscriberModel } from 'redux/services/api.generated';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './SubscriberList.module.css';
import { useTranslation } from 'react-i18next';

interface SubscriberListProps {
  selectedSubscriber: SubscriberModel | undefined;
  setSelectedSubscriber: Dispatch<SetStateAction<SubscriberModel | undefined>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  inputValue: string | null;
}

const pageSize = 10;

const SubscriberList = ({
  selectedSubscriber,
  setSelectedSubscriber,
  page,
  setPage,
  inputValue,
}: SubscriberListProps) => {
  const { t } = useTranslation();
  const [subscribers, setSubscribers] = useState<SubscriberModel[]>([]);

  const [
    fetchSubscribers,
    {
      data: subscribersData,
      isFetching: isFetchingSubscribers,
      isLoading: isLoadingSubscribers,
    },
  ] = generatedApi.useLazySearchSubscribersQuery();

  const menuListRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    void fetchSubscribers({
      query: inputValue || '',
      page: 1,
      pageSize,
    }).then(({ data }) => {
      if (data?.items) {
        setSubscribers(data?.items);
      }
      if (data?.items.length === 1) {
        setSelectedSubscriber(data?.items[0]);
      }
    });
  }, [fetchSubscribers, inputValue, setSelectedSubscriber]);

  const handleLoadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);

    const { data: dataToAppend } = await fetchSubscribers({
      query: inputValue || '',
      page: newPage,
      pageSize,
    });
    if (dataToAppend?.items && dataToAppend.items?.length) {
      setSubscribers((prevState) => [...prevState, ...dataToAppend?.items]);
    }
  };

  // Scroll to the bottom of the list after clicking "load more". This solution
  // doesn't work well when using keyboard because the Menu component autofocuses
  // the first element.
  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.scrollTop = menuListRef.current.scrollHeight;
    }
  }, [subscribers, menuListRef]);

  if (isLoadingSubscribers) {
    return <Spinner />;
  }

  if (!isFetchingSubscribers && !subscribers.length) {
    return (
      <Typography color="danger">{t('subscribers.noSubscribersFound')}</Typography>
    );
  }

  return (
    <>
      <Typography>
        {t('subscribers.showingCountSubscribers', { count: subscribers.length })}
      </Typography>
      <MenuList
        aria-label={t('subscribers.subscribers')}
        role="listbox"
        className={
          isFetchingSubscribers
            ? styles.isFetchingSubscriber
            : styles.subscribersList
        }
        sx={{ '--page-size': pageSize }}
        ref={menuListRef}
      >
        {subscribers?.map((item, index) => (
          <MenuItem
            role="option"
            key={`${item.id}-${index}`}
            selected={selectedSubscriber?.id === item.id}
            aria-selected={selectedSubscriber?.id === item.id}
            onClick={() => setSelectedSubscriber(item)}
            className={styles.subscriber}
          >
            <span className={styles.customerId}>{item.customerId}</span>
            <span>{item.name}</span>
          </MenuItem>
        ))}
        {isFetchingSubscribers && <Spinner position="centerAbsolute" size="md" />}
      </MenuList>
      {subscribersData && subscribersData.totalCount > pageSize && (
        <Button
          onClick={() => void handleLoadMore()}
          disabled={!subscribersData?.hasNextPage}
          className={styles.loadMoreButton}
          variant="outlined"
        >
          {t('general.loadMore')}
        </Button>
      )}
    </>
  );
};

export default SubscriberList;
