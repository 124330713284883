import DiscontinueModal, {
  DeactivationMethod,
} from 'components/global/DiscontinueModal/DiscontinueModal';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { showMessage } from 'redux/slices/messageSlice';
import { formatDate } from '../../../../../util/util';
import { formatISO } from 'date-fns';
import { useAppDispatch } from 'redux/hooks';
import {
  useDeactivateSubscriptionMutation,
  useDiscontinueSubscriptionMutation,
} from 'redux/services/api.generated';
import { useTranslation } from 'react-i18next';

interface EndSubscriptionModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  subscriptionId: string;
  invoicePeriodEndDate?: string | null;
  refetchSubscriptions: () => void;
  datePickerMaxDate?: string;
}

const EndSubscriptionModal = ({
  isOpen,
  setIsOpen,
  subscriptionId,
  invoicePeriodEndDate,
  refetchSubscriptions,
  datePickerMaxDate,
}: EndSubscriptionModalProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [discontinue] = useDiscontinueSubscriptionMutation();
  const [deactivate] = useDeactivateSubscriptionMutation();

  const handleSubmit = (
    event: FormEvent<HTMLFormElement>,
    inactiveDate: Date | null,
    closeModal: () => void,
    deactivationMethod: DeactivationMethod,
  ) => {
    event.preventDefault();
    closeModal();

    if (deactivationMethod === DeactivationMethod.discontinue) {
      discontinue({ subscriptionId })
        .unwrap()
        .then(() => {
          refetchSubscriptions();
          dispatch(
            showMessage({
              text: t('subscriptions.subscriptionHasBeenDiscontinued', {
                subscriptionId,
                date: formatDate(invoicePeriodEndDate ?? ''),
              }),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
    if (deactivationMethod === DeactivationMethod.deactivateToday) {
      deactivate({ subscriptionId })
        .unwrap()
        .then(() => {
          refetchSubscriptions();
          dispatch(
            showMessage({
              text: t('subscriptions.subscriptionHasBeenDeactivated', {
                subscriptionId,
              }),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
    if (deactivationMethod === DeactivationMethod.deactivateOnDate) {
      deactivate({
        subscriptionId,
        inactiveDate: inactiveDate ? formatDate(formatISO(inactiveDate)) : undefined,
      })
        .unwrap()
        .then(() => {
          refetchSubscriptions();
          dispatch(
            showMessage({
              text: t('subscriptions.subscriptionWillBeDeactivated', {
                subscriptionId,
                date: formatDate(formatISO(inactiveDate as Date)),
              }),
              color: 'success',
            }),
          );
        })
        .catch(() => {
          // Errors are handled in middleware.
        });
    }
  };

  return (
    <DiscontinueModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit}
      invoicePeriodEndDate={invoicePeriodEndDate}
      title={`${t('subscriptions.endSubscription')} ${subscriptionId}`}
      description={t('subscriptions.whenDeactivateSubscription')}
      datePickerMaxDate={datePickerMaxDate}
    />
  );
};

export default EndSubscriptionModal;
