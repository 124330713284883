import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Textarea,
  Typography,
} from '@mui/joy';
import {
  useDeleteSubscriptionNoteMutation,
  useUpdateSubscriptionNoteMutation,
} from 'redux/services/api.generated';
import {
  ChangeEventHandler,
  Dispatch,
  FocusEventHandler,
  FormEventHandler,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionModel } from 'redux/services/api.generated';
import styles from './NoteModal.module.css';
import { showMessage } from 'redux/slices/messageSlice';
import { useAppDispatch } from 'redux/hooks';

interface NoteModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  note?: string;
  subscriptionId: SubscriptionModel['subscriptionId'];
  refetchSubscriptions: () => void;
}

const NoteModal = ({
  isOpen,
  setIsOpen,
  note,
  subscriptionId,
  refetchSubscriptions,
}: NoteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteNote] = useDeleteSubscriptionNoteMutation();
  const [updateNote] = useUpdateSubscriptionNoteMutation();

  const [validationError, setValidationError] = useState('');

  const closeDialog = () => {
    setIsOpen(false);
    setValidationError('');
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const noteData = formData.get('note');

    if (validationError) {
      return;
    }

    updateNote({
      subscriptionId,
      updateNoteRequest: { note: (noteData as string).trim() },
    })
      .unwrap()
      .then(() => {
        refetchSubscriptions();
        closeDialog();
        dispatch(
          showMessage({
            text: t('note.noteUpdated', {
              subscriptionId,
            }),
            color: 'success',
          }),
        );
      })
      .catch(() => {
        closeDialog();
        // Error message is handled in middleware.
      });
  };

  const onNoteDelete = () => {
    deleteNote({ subscriptionId })
      .unwrap()
      .then(() => {
        refetchSubscriptions();
        closeDialog();
        dispatch(
          showMessage({
            text: t('note.noteDeleted', {
              subscriptionId,
            }),
            color: 'success',
          }),
        );
      })
      .catch(() => {
        // Errors are handled in middleware.
      });
  };

  const validateTooLong: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.currentTarget.value.length > 500) {
      setValidationError(t('note.tooLong'));
    } else {
      setValidationError('');
    }
  };

  // Do this on blur to avoid showing error when dialog opens.
  const validateTooShort: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.currentTarget.value === '') {
      setValidationError(t('note.tooShort'));
    }
  };

  return (
    <Modal open={isOpen} onClose={closeDialog}>
      <ModalDialog className={styles.modal} aria-labelledby="note-modal-title">
        <ModalClose aria-label={t('general.close')} />
        <Typography
          id="note-modal-title"
          level="heading4"
          component="h2"
          className={styles.title}
        >
          {t('note.internalNoteTitle', {
            action: note ? t('note.editNote') : t('note.createNote'),
            subscriptionId,
          })}
        </Typography>
        <form onSubmit={onSubmit} aria-labelledby="note-modal-title">
          <FormControl>
            <FormLabel>{t('note.internalNote')}</FormLabel>
            <Textarea
              name="note"
              required
              onChange={validateTooLong}
              onBlur={validateTooShort}
              defaultValue={note}
              error={Boolean(validationError)}
              maxRows={10}
            />
            {validationError && (
              <FormHelperText role="alert">
                <Sheet color="danger">{validationError}</Sheet>
              </FormHelperText>
            )}
          </FormControl>
          <footer className={styles.footer}>
            {note && (
              <Button variant="outlined" color="danger" onClick={onNoteDelete}>
                {t('general.delete')}
              </Button>
            )}
            <Button variant="outlined" onClick={closeDialog}>
              {t('general.cancel')}
            </Button>
            <Button type="submit" disabled={Boolean(validationError)}>
              {t('general.save')}
            </Button>
          </footer>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default NoteModal;
