import { Alert, Typography } from '@mui/joy';
import styles from './SubscriptionList.module.css';
import Spinner from 'components/global/Spinner/Spinner';
import SubscriptionItem from 'components/pages/Subscriptions/SubscriptionList/SubscriptionItem/SubscriptionItem';
import { useTranslation } from 'react-i18next';
import {
  SubscriberModel,
  SubscriptionModel,
  useListSubscriptionsQuery,
} from 'redux/services/api.generated';
import { AlertTriangleIcon } from 'lucide-react';
import SubscriptionFilters from '../SubscriptionFilters/SubscriptionFilters';
import { useEffect, useMemo, useRef, useState } from 'react';

export type SubscriptionStatuses = Record<
  SubscriptionModel['subscriptionStatus'],
  boolean
>;

// The key under which we store subscription statuses in local storage.
const subscriptionStatusesKey = 'sm-subscription-statuses';

interface SubscriptionListProps {
  selectedSubscriber: SubscriberModel | undefined;
}

export const initialSubscriptionStatuses: SubscriptionStatuses = {
  Active: true,
  PendingActive: true,
  PendingInactive: false,
  Inactive: false,
  Discontinued: false,
  Expired: false,
  Draft: false,
  Quotation: false,
};

const SubscriptionList = ({ selectedSubscriber }: SubscriptionListProps) => {
  const { t } = useTranslation();
  const subscriptionListRef = useRef<HTMLUListElement | null>(null);

  const { data, isError, isFetching, isLoading, isUninitialized, refetch } =
    useListSubscriptionsQuery(
      {
        subscriberId: selectedSubscriber?.id as string,
      },
      { skip: !selectedSubscriber?.id },
    );

  let localStorageStatuses = initialSubscriptionStatuses;

  try {
    // JSON.parse will throw an exception if the JSON is invalid.
    if (localStorage.getItem(subscriptionStatusesKey)) {
      localStorageStatuses = JSON.parse(
        localStorage.getItem(subscriptionStatusesKey) as string,
      ) as SubscriptionStatuses;
    }
  } catch {}

  const [statuses, setStatuses] =
    useState<SubscriptionStatuses>(localStorageStatuses);

  const filteredSubscriptions = useMemo(
    () =>
      data?.filter(({ subscriptionStatus }) => statuses[subscriptionStatus]) || [],
    [data, statuses],
  );

  useEffect(() => {
    localStorage.setItem(subscriptionStatusesKey, JSON.stringify(statuses));
  }, [statuses]);

  useEffect(() => {
    const list = subscriptionListRef.current;
    if (!list) {
      return;
    }

    // Set tabIndex to 0, when a list item is clicked.
    list.querySelectorAll('li').forEach((listItem) => {
      listItem.addEventListener('click', () => {
        const target = listItem;
        listItem.parentElement?.childNodes.forEach((child) => {
          // eslint-disable-next-line no-param-reassign
          (child as HTMLLIElement).tabIndex = -1;
        });
        target.tabIndex = 0;
      });
    });

    const subscriptionItemKeyboardHandler = (event: KeyboardEvent) => {
      const activeListItem: HTMLLIElement | null =
        list.querySelector('li[tabindex="0"]');
      if (!activeListItem) {
        return;
      }

      let nextListItem: HTMLLIElement | null = null;
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        nextListItem = activeListItem.nextSibling as HTMLLIElement | null;
        if (!nextListItem) {
          nextListItem = list.querySelector('li:first-child');
        }
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        nextListItem = activeListItem.previousSibling as HTMLLIElement | null;
        if (!nextListItem) {
          nextListItem = list.querySelector('li:last-child');
        }
      }
      if (event.key === 'Home') {
        nextListItem = list.querySelector('li:first-child');
      }
      if (event.key === 'End') {
        nextListItem = list.querySelector('li:last-child');
      }

      if (nextListItem) {
        activeListItem.tabIndex = -1;
        nextListItem.tabIndex = 0;
        nextListItem.focus();
      }
    };

    list.addEventListener('keydown', subscriptionItemKeyboardHandler);
    return () => {
      list.removeEventListener('keydown', subscriptionItemKeyboardHandler);
    };
  }, [filteredSubscriptions]);

  if (isUninitialized) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <Alert
        color="danger"
        size="lg"
        className={styles.alert}
        startDecorator={<AlertTriangleIcon />}
      >
        <Typography level="bodyXl" component="p">
          {t('subscriptions.fetchError')}
        </Typography>
      </Alert>
    );
  }

  return (
    <section>
      <div className={styles.topRow}>
        <Typography level="heading2" component="h2" id="subscriptionsHeading">
          {t('subscriptions.subscriptions')} – {selectedSubscriber?.name}
        </Typography>
        {data && data.length > 0 && (
          <SubscriptionFilters statuses={statuses} setStatuses={setStatuses} />
        )}
      </div>
      {!data || !data.length ? (
        <Typography>{t('subscriptions.noSubscriptionsFound')}</Typography>
      ) : (
        <>
          <Typography level="labelMd" className={styles.visibleSubscriptionsMessage}>
            {t('subscriptions.visibleSubscriptionsCount', {
              filteredCount: filteredSubscriptions.length,
              totalCount: data.length,
            })}
          </Typography>
          <ul ref={subscriptionListRef} aria-labelledby="subscriptionsHeading">
            {filteredSubscriptions.map((subscription, index) => (
              <SubscriptionItem
                subscription={subscription as SubscriptionModel}
                key={subscription.subscriptionId}
                index={index}
                refetchSubscriptions={() => void refetch()}
                isFetching={isFetching}
              />
            ))}
          </ul>
        </>
      )}
    </section>
  );
};

export default SubscriptionList;
