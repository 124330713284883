interface SilentLoginProps {
  onCompleted: (result: boolean) => void;
}

const SilentLogin = ({ onCompleted }: SilentLoginProps) => {
  const eventHandler = (
    e: MessageEvent<{ source: string; isLoggedIn: boolean }>,
  ) => {
    if (e?.data?.source === 'bff-silent-login') {
      onCompleted(e.data.isLoggedIn);
      window.removeEventListener('message', eventHandler);
    }
  };

  window.addEventListener('message', eventHandler);

  return (
    <iframe
      style={{ display: 'none' }}
      id="bff-silent-login"
      title="bff-silent-login"
      src="/bff/silent-login"
    />
  );
};

export default SilentLogin;
