import LinkButton from '@standardsdigital/ui-components/LinkButton';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { appName } from 'util/constants';
import { useGetBffUserQuery } from 'redux/services/bffUserApi';
import styles from './AccessDeniedPage.module.css';

const AccessDeniedPage = () => {
  const { t } = useTranslation();

  const { data: claims } = useGetBffUserQuery();
  const logOutUrl = claims?.find((claim) => claim.type === 'bff:logout_url')?.value;

  const title = `${String(t('authentication.unauthorizedTitle'))} | ${appName}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className={styles.main}>
        <h1 className="sd-heading-lg">{t('authentication.unauthorizedTitle')}</h1>
        <p className={styles.message}>{t('authentication.unauthorizedMessage')}</p>
        <LinkButton href={logOutUrl} variant="primary">
          {t('authentication.logOut')}
        </LinkButton>
      </main>
    </>
  );
};

export default AccessDeniedPage;
