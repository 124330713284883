import DiscontinueSubscriptionLineModal from 'components/pages/SubscriptionLines/DiscontinueSubscriptionLineModal/DiscontinueSubscriptionLineModal';
import styles from './SubscriptionLines.module.css';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { formatDate } from '../../../util/util';
import { useTranslation } from 'react-i18next';
import SubscriptionLinesDataGrid from './SubscriptionLinesDataGrid/SubscriptionLinesDataGrid';
import { Button, Link, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import { GridCellParams, GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';
import {
  CollectionModel,
  StandardModel,
  useBulkReactivateSubscriptionLinesMutation,
  useGetSubscriptionQuery,
  useListSubscriptionCollectionsQuery,
  useListSubscriptionStandardsQuery,
  useListSubscriptionEformsQuery,
  EformModel,
  LineStatus,
} from 'redux/services/api.generated';
import { useEffect, useState } from 'react';
import { setAppTitle } from 'redux/slices/appSlice';
import { useAppDispatch } from 'redux/hooks';
import { CalendarOffIcon, CornerUpLeftIcon, RecycleIcon } from 'lucide-react';
import Spinner from 'components/global/Spinner/Spinner';
import { showMessage } from 'redux/slices/messageSlice';
import SubscriptionDetails from './SubscriptionDetails/SubscriptionDetails';
import StatusIndicator, {
  StatusIndicatorProps,
} from 'components/global/StatusIndicator/StatusIndicator';
import { buttonIconSize } from 'util/constants';
import { api } from 'redux/services/api';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import ProductSearch from 'components/pages/SubscriptionLines/ProductSearch/ProductSearch';

export enum SubscriptionLinesTabEnum {
  Standards = 'standards',
  Collections = 'collections',
  Eforms = 'eforms',
}

const SubscriptionLines = () => {
  const { t } = useTranslation();
  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();

  const [discontinueModalOpen, setDiscontinueModalOpen] = useState(false);

  const [includeStandardsInCollections, setIncludeStandardsInCollections] =
    useState(false);

  const [selectedTab, setSelectedTab] = useState<SubscriptionLinesTabEnum | null>(
    null,
  );

  const [activeSubscriptionLine, setActiveSubscriptionLine] = useState<{
    id: number;
    name: string;
    status: StandardModel['lineStatus'] | CollectionModel['lineStatus'];
  } | null>(null);

  const [standardsFilterModel, setStandardsFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [collectionsFilterModel, setCollectionsFilterModel] =
    useState<GridFilterModel>({ items: [] });

  const [eformsFilterModel, setEformsFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const {
    data: subscriptionDetails,
    isLoading: isLoadingSubscriptionDetails,
    isError: isFetchSubscriptionDetailsError,
  } = useGetSubscriptionQuery(
    {
      subscriptionId: subscriptionId as string,
    },
    { skip: !subscriptionId },
  );

  const isEform =
    subscriptionDetails?.serviceLevel === 'eForms' &&
    subscriptionDetails.documentServices.includes('Eform');

  const {
    data: collections,
    isLoading: isLoadingCollections,
    isFetching: isFetchingCollections,
    isError: isFetchCollectionsError,
  } = useListSubscriptionCollectionsQuery(
    {
      subscriptionId: subscriptionId as string,
    },
    { skip: !subscriptionId },
  );

  const {
    data: standards,
    isLoading: isLoadingStandards,
    isFetching: isFetchingStandards,
    isError: isFetchStandardsError,
  } = useListSubscriptionStandardsQuery(
    {
      subscriptionId: subscriptionId as string,
      includeStandardsInCollections,
    },
    { skip: !subscriptionId },
  );

  const {
    data: eforms,
    isLoading: isLoadingEforms,
    isFetching: isFetchingEforms,
    isError: isFetchEformsError,
  } = useListSubscriptionEformsQuery(
    {
      subscriptionId: subscriptionId as string,
    },
    { skip: !subscriptionId },
  );

  const [reactivateSubscriptionLines] = useBulkReactivateSubscriptionLinesMutation();

  const refetch = () => {
    if (!selectedTab || !subscriptionId) {
      return;
    }

    dispatch(
      api.util.invalidateTags([
        { type: 'SubscriptionLines', id: `${subscriptionId}:${selectedTab}` },
      ]),
    );
  };

  useEffect(() => {
    if (subscriptionDetails && subscriptionId) {
      dispatch(
        setAppTitle({
          translationKey: 'subscriptions.standards',
          options: {
            id: subscriptionId,
            name: subscriptionDetails.description,
          },
        }),
      );
    }
  }, [dispatch, subscriptionDetails, subscriptionId]);

  useEffect(() => {
    if (isLoadingStandards || selectedTab !== null) {
      return;
    }
    if (standards?.length) {
      setSelectedTab(SubscriptionLinesTabEnum.Standards);
    } else if (collections?.length) {
      setSelectedTab(SubscriptionLinesTabEnum.Collections);
    } else if (eforms?.length) {
      setSelectedTab(SubscriptionLinesTabEnum.Eforms);
    }
  }, [standards, collections, eforms, isLoadingStandards, selectedTab]);

  const getStatusColor = (status: LineStatus) => {
    let statusColor: StatusIndicatorProps['color'] = 'neutral';
    switch (status) {
      case LineStatus.Active:
        statusColor = 'success';
        break;
      case LineStatus.PendingInactive:
      case LineStatus.PendingActive:
      case LineStatus.Discontinued:
        statusColor = 'warning';
        break;
      case LineStatus.Inactive:
      case LineStatus.Expired:
        statusColor = 'danger';
        break;
    }
    return statusColor;
  };

  const getStatusLabel = (
    value: string | undefined,
    row: StandardModel | CollectionModel | EformModel,
  ) => {
    if (!value || !row) {
      return;
    }
    if (row.lineStatus === 'Discontinued') {
      return t('subscriptions.discontinuedFrom', {
        date: formatDate(row?.inactiveDate) || '',
      });
    }
    if (row?.inactiveDate) {
      return `${value} ${formatDate(row.inactiveDate)}`;
    }
    return value;
  };

  const handleReactivate = (row: StandardModel | CollectionModel | EformModel) => {
    reactivateSubscriptionLines({
      bulkReactivateSubscriptionLinesCommand: {
        subscriptionLineIds: [row.id],
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(
          showMessage({
            text: t('subscriptions.subscriptionHasBeenReactivated', {
              subscriptionId: row.id,
            }),
            color: 'success',
          }),
        );
      })
      .catch(() => {
        // Errors are handled in middleware.
      });
  };

  const getRowActionButton = ({
    row,
    tabIndex,
  }: GridCellParams<StandardModel | CollectionModel>) => {
    if (
      row.lineType === 'StandardInCollection' ||
      row.lineStatus === 'Expired' ||
      (subscriptionDetails &&
        ['Inactive', 'Discontinued'].includes(
          subscriptionDetails.subscriptionStatus,
        ))
    ) {
      return;
    }
    if (row.lineStatus === 'Active') {
      return (
        <Button
          variant="outlined"
          size="sm"
          startDecorator={<CalendarOffIcon size={buttonIconSize} />}
          onClick={() => {
            setActiveSubscriptionLine({
              id: row.id,
              name: row.name,
              status: row.lineStatus,
            });
            setDiscontinueModalOpen(true);
          }}
          tabIndex={tabIndex}
        >
          {t('subscriptions.deactivate')}
        </Button>
      );
    }
    if (['Inactive', 'PendingInactive', 'Discontinued'].includes(row.lineStatus)) {
      return (
        <Button
          variant="outlined"
          size="sm"
          startDecorator={<RecycleIcon size={buttonIconSize} />}
          onClick={() => handleReactivate(row)}
          tabIndex={tabIndex}
        >
          {t('subscriptions.reactivate')}
        </Button>
      );
    }
  };

  const defaultColumnProps: Partial<GridColDef> = {
    renderCell: ({ value }) => <span className={styles.ellipsis}>{value}</span>,
  };

  const getSingleSelectOptions = <
    Row extends StandardModel | CollectionModel | EformModel,
    Key extends keyof Row,
  >(
    rows: Row[] | undefined,
    field: Key,
  ): Array<Row[Key]> => {
    if (!rows) {
      return [];
    }
    return [...new Set(rows.map((row) => row[field]))];
  };

  const getStatusValueOptions = <
    Row extends StandardModel | CollectionModel | EformModel,
  >(
    rows: Row[] | undefined,
  ) => {
    const statuses = getSingleSelectOptions(rows, 'lineStatus');
    const localizedOptions = statuses.map((status) =>
      t(`subscriptionStatuses.${status}`),
    );
    return localizedOptions;
  };

  const standardColumns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'name',
      headerName: t('labels.standardReference'),
      flex: 1,
      minWidth: 150,
    },
    {
      ...defaultColumnProps,
      field: 'sdo',
      headerName: t('labels.sdo'),
      width: 150,
      type: 'singleSelect',
      valueOptions: getSingleSelectOptions(standards, 'sdo'),
    },
    {
      ...defaultColumnProps,
      field: 'collectionName',
      headerName: t('labels.collectionName'),
      flex: 1,
      minWidth: 150,
      renderCell: ({
        value,
        row,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        row.lineType === 'StandardInCollection' && !value
          ? t('labels.collection')
          : value,
    },
    {
      ...defaultColumnProps,
      field: 'dateAdded',
      headerName: t('labels.addedDate'),
      type: 'date',
      valueGetter: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        value && new Date(value),
      renderCell: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        formatDate(value),
      width: 120,
    },
    {
      ...defaultColumnProps,
      field: 'lineSource',
      headerName: t('labels.lineSource'),
      width: 150,
      type: 'singleSelect',
      valueOptions: getSingleSelectOptions(standards, 'lineSource'),
    },
    {
      ...defaultColumnProps,
      field: 'lineStatus',
      type: 'singleSelect',
      valueOptions: getStatusValueOptions(standards),
      valueGetter: ({ value }) =>
        t(`subscriptionStatuses.${value as StandardModel['lineStatus']}`),
      renderCell: ({
        value,
        row,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) => (
        <StatusIndicator
          color={getStatusColor(row.lineStatus)}
          type="subscriptionLine"
        >
          {getStatusLabel(value, row)}
        </StatusIndicator>
      ),
      headerName: t('labels.status'),
      flex: 0.5,
      minWidth: 260,
    },
    {
      ...defaultColumnProps,
      field: 'actions',
      headerName: t('labels.actions'),
      width: 150,
      renderCell: getRowActionButton,
      filterable: false,
      sortable: false,
    },
  ];

  const collectionColumns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'name',
      headerName: t('labels.collectionName'),
      flex: 1,
      minWidth: 150,
    },
    {
      ...defaultColumnProps,
      field: 'lineStatus',
      headerName: t('labels.status'),
      flex: 0.5,
      minWidth: 260,
      type: 'singleSelect',
      valueOptions: getStatusValueOptions(collections),
      valueGetter: ({ value }) =>
        t(`subscriptionStatuses.${value as StandardModel['lineStatus']}`),
      renderCell: ({
        value,
        row,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) => (
        <StatusIndicator
          color={getStatusColor(row.lineStatus)}
          type="subscriptionLine"
        >
          {getStatusLabel(value, row)}
        </StatusIndicator>
      ),
    },
    {
      ...defaultColumnProps,
      field: 'dateAdded',
      headerName: t('labels.addedDate'),
      type: 'date',
      valueGetter: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        value && new Date(value),
      renderCell: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        formatDate(value),
      width: 120,
    },
    {
      ...defaultColumnProps,
      field: 'lineSource',
      headerName: t('labels.lineSource'),
      width: 150,
      type: 'singleSelect',
      valueOptions: getSingleSelectOptions(collections, 'lineSource'),
    },
    {
      ...defaultColumnProps,
      field: 'collectionStandardCount',
      type: 'number',
      headerName: t('labels.productCount'),
      width: 150,
    },
    {
      ...defaultColumnProps,
      field: 'actions',
      headerName: t('labels.actions'),
      width: 150,
      renderCell: getRowActionButton,
      filterable: false,
      sortable: false,
    },
  ];

  const eformColumns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'name',
      headerName: t('labels.eform'),
      flex: 1,
      minWidth: 150,
    },
    {
      ...defaultColumnProps,
      field: 'sdo',
      headerName: t('labels.sdo'),
      width: 150,
      type: 'singleSelect',
      valueOptions: getSingleSelectOptions(eforms, 'sdo'),
    },
    {
      ...defaultColumnProps,
      field: 'dateAdded',
      headerName: t('labels.addedDate'),
      type: 'date',
      valueGetter: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        value && new Date(value),
      renderCell: ({
        value,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) =>
        formatDate(value),
      width: 120,
    },
    {
      ...defaultColumnProps,
      field: 'lineSource',
      headerName: t('labels.lineSource'),
      width: 150,
      type: 'singleSelect',
      valueOptions: getSingleSelectOptions(eforms, 'lineSource'),
    },
    {
      ...defaultColumnProps,
      field: 'count',
      headerName: t('labels.count'),
      width: 120,
      type: 'number',
    },
    {
      ...defaultColumnProps,
      field: 'lineStatus',
      type: 'singleSelect',
      valueOptions: getStatusValueOptions(eforms),
      valueGetter: ({ value }) =>
        t(`subscriptionStatuses.${value as StandardModel['lineStatus']}`),
      renderCell: ({
        value,
        row,
      }: GridCellParams<StandardModel | CollectionModel | EformModel, string>) => (
        <StatusIndicator
          color={getStatusColor(row.lineStatus)}
          type="subscriptionLine"
        >
          {getStatusLabel(value, row)}
        </StatusIndicator>
      ),
      headerName: t('labels.status'),
      flex: 0.5,
      minWidth: 260,
    },
    {
      ...defaultColumnProps,
      field: 'actions',
      headerName: t('labels.actions'),
      width: 150,
      renderCell: getRowActionButton,
      filterable: false,
      sortable: false,
    },
  ];

  if (
    isFetchSubscriptionDetailsError &&
    isFetchStandardsError &&
    isFetchCollectionsError &&
    isFetchEformsError
  ) {
    return <NotFoundPage />;
  }

  if (
    isLoadingSubscriptionDetails ||
    isLoadingCollections ||
    isLoadingStandards ||
    isLoadingEforms
  ) {
    return <Spinner />;
  }

  return (
    <main className={styles.main}>
      {activeSubscriptionLine && (
        <DiscontinueSubscriptionLineModal
          isOpen={discontinueModalOpen}
          setIsOpen={setDiscontinueModalOpen}
          subscriptionLines={[activeSubscriptionLine]}
          refetch={refetch}
          datePickerMaxDate={subscriptionDetails?.agreementPeriodEndDate}
        />
      )}
      <SubscriptionDetails
        subscriptionDetails={subscriptionDetails}
        className={styles.subscriptionDetails}
      />
      <Tabs
        size="md"
        className={styles.tabs}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value as SubscriptionLinesTabEnum)}
      >
        <div className={styles.tabNavigation}>
          <Link
            component={RouterLink}
            to={`/?q=${subscriptionDetails?.customerId || ''}`}
            className={styles.returnLink}
          >
            <>
              <CornerUpLeftIcon size="1.125em" strokeWidth={2.5} />
              {t('subscriptions.returnToSubscriptions')}
            </>
          </Link>
          <TabList variant="outlined" className={styles.tabList}>
            <Tab
              disabled={
                !selectedTab ||
                (!standards?.length && !collections?.length) ||
                isFetchStandardsError
              }
              value="standards"
            >
              {t('labels.standards')}
            </Tab>
            <Tab disabled={!selectedTab || !collections?.length} value="collections">
              {t('labels.collections')}
            </Tab>
            <Tab disabled={!selectedTab || !eforms?.length} value="eforms">
              {t('labels.eforms')}
            </Tab>
          </TabList>
          {subscriptionId && (
            <ProductSearch
              isEform={isEform}
              subscriptionId={subscriptionId}
              isActiveSubscription={
                subscriptionDetails?.subscriptionStatus === 'Active'
              }
            />
          )}
        </div>
        <TabPanel value="standards" className={styles.tabPanel}>
          <SubscriptionLinesDataGrid
            key="standards"
            name="standards"
            rows={standards}
            columns={standardColumns}
            enableSearch
            setIncludeStandardsInCollections={
              collections && collections.length > 0
                ? setIncludeStandardsInCollections
                : undefined
            }
            includeStandardsInCollections={
              collections && collections.length > 0
                ? includeStandardsInCollections
                : undefined
            }
            refetch={refetch}
            filterModel={standardsFilterModel}
            setFilterModel={setStandardsFilterModel}
            datePickerMaxDate={subscriptionDetails?.agreementPeriodEndDate}
            subscriptionStatus={subscriptionDetails?.subscriptionStatus}
            isFetching={isFetchingStandards}
          />
        </TabPanel>
        {collections && collections.length > 0 && (
          <TabPanel value="collections" className={styles.tabPanel}>
            <SubscriptionLinesDataGrid
              key="collections"
              name="collections"
              rows={collections}
              columns={collectionColumns}
              refetch={refetch}
              filterModel={collectionsFilterModel}
              setFilterModel={setCollectionsFilterModel}
              datePickerMaxDate={subscriptionDetails?.agreementPeriodEndDate}
              subscriptionStatus={subscriptionDetails?.subscriptionStatus}
              isFetching={isFetchingCollections}
            />
          </TabPanel>
        )}
        {eforms && eforms.length > 0 && (
          <TabPanel value="eforms" className={styles.tabPanel}>
            <SubscriptionLinesDataGrid
              key="eforms"
              name="eforms"
              rows={eforms}
              columns={eformColumns}
              refetch={refetch}
              filterModel={eformsFilterModel}
              setFilterModel={setEformsFilterModel}
              datePickerMaxDate={subscriptionDetails?.agreementPeriodEndDate}
              subscriptionStatus={subscriptionDetails?.subscriptionStatus}
              isFetching={isFetchingEforms}
            />
          </TabPanel>
        )}
      </Tabs>
    </main>
  );
};

export default SubscriptionLines;
