import { useTranslation } from 'react-i18next';
import { Button } from '@mui/joy';
import { AvailableLanguages, supportedLanguages } from '../../../../i18nConfig';
import styles from './LanguageSelector.module.css';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const languageStyle = (language: AvailableLanguages) =>
    language === i18n.language
      ? `${styles.language} ${styles.activeLanguage}`
      : styles.language;

  return (
    <div
      className={styles.languageSelector}
      role="toolbar"
      aria-label={t('general.changeLanguage')}
    >
      {supportedLanguages().map((language) => (
        <Button
          lang={language}
          className={languageStyle(language)}
          key={language}
          onClick={() => void i18n.changeLanguage(language)}
          aria-label={t(`languages.${language}`)}
          aria-current={language === i18n.language}
        >
          {language}
        </Button>
      ))}
    </div>
  );
};

export default LanguageSelector;
