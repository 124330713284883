import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/api';
import { bffUserApi } from './services/bffUserApi';
import { messageReducer } from './slices/messageSlice';
import { appReducer } from './slices/appSlice';
import { errorHandler } from './middleware/errorHandlerMiddleware';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [bffUserApi.reducerPath]: bffUserApi.reducer,
    message: messageReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      bffUserApi.middleware,
      errorHandler,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
