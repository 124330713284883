import { Helmet } from 'react-helmet-async';
import { getActiveCustomer } from '../../../util/util';

const Favicon = () => {
  // Use Helmet for implementation of favicons to be ready for multiple themes.
  // See this guide for explanation of files used:
  // https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs

  const theme = getActiveCustomer();
  const themePath = `/favicons/${theme}`;

  return (
    <Helmet>
      <link rel="icon" href={`${themePath}/favicon.ico`} sizes="any" />
      <link rel="icon" href={`${themePath}/favicon.svg`} type="image/svg+xml" />
      <link rel="apple-touch-icon" href={`${themePath}/apple-touch-icon.png`} />
      <link rel="manifest" href={`${themePath}/manifest.json`} />
    </Helmet>
  );
};

export default Favicon;
