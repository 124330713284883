import { LanguageDetectionOptions } from './types/languageDetectionOptions';

export const languageDetectionOptions: LanguageDetectionOptions = {
  // Specify where the user's language should be detected from and order.
  order: ['querystring', 'localStorage', 'navigator'],

  lookupQuerystring: 'lng',
  lookupLocalStorage: 'sm-ui-language',

  // Enable caching of user's language.
  caches: ['localStorage'],
};

export enum AvailableLanguages {
  en = 'en',
  no = 'no',
}

// If everything else fails, use Norwegian as default language.
const internalFallbackLanguageCode = AvailableLanguages.no;

const fallbackLanguageCode: AvailableLanguages =
  (import.meta.env.REACT_APP_FALLBACK_LANGUAGE as AvailableLanguages) ||
  internalFallbackLanguageCode;

export const fallbackLanguage = fallbackLanguageCode;

const requestedLanguages: AvailableLanguages[] =
  import.meta.env.REACT_APP_UI_LANGUAGES?.split(',').map(
    (lng) => lng as AvailableLanguages,
  ) || [fallbackLanguageCode];

export const supportedLanguages = (): AvailableLanguages[] =>
  requestedLanguages.filter((language) => AvailableLanguages[language]);
