import { PropsWithChildren } from 'react';
import { List } from '@mui/joy';
import styles from './DataList.module.css';

interface DataListProps extends PropsWithChildren {
  className?: string;
}

const DataList = ({ children, className }: DataListProps) => {
  let classes = styles.dataList;
  if (className) {
    classes += ` ${className}`;
  }

  return (
    <List component="dl" orientation="horizontal" className={classes}>
      {children}
    </List>
  );
};

export default DataList;
