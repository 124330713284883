import { ListItem, Typography } from '@mui/joy';
import styles from './DataListItem.module.css';

interface DataListItemProps {
  label: string;
  value: string | number;
  fullWidth?: boolean;
}

const DataListItem = ({ label, value, fullWidth }: DataListItemProps) => {
  if (!value) {
    return null;
  }

  let classes = styles.listItem;
  if (fullWidth) {
    classes += ` ${styles.fullWidth}`;
  }

  return (
    <ListItem component="div" className={classes}>
      <Typography level="labelSm" component="dt" className={styles.label}>
        {label}
      </Typography>
      <Typography level="bodyMd" component="dd" className={styles.value}>
        {value}
      </Typography>
    </ListItem>
  );
};

export default DataListItem;
