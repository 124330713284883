import { Link } from 'react-router-dom';
import { LayoutDashboardIcon, UserIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { getActiveCustomer } from '../../../util/util';
import styles from './Header.module.css';
import { Typography, Link as JoyLink } from '@mui/joy';
import { useGetBffUserQuery } from 'redux/services/bffUserApi';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const Header = () => {
  const { data: claims } = useGetBffUserQuery();
  const { t, i18n } = useTranslation();
  const theme = getActiveCustomer();

  const { appTitle } = useSelector((state: RootState) => state.app);

  const logoutUrl = claims?.find((claim) => claim.type === 'bff:logout_url')?.value;

  const [logoPath, setLogoPath] = useState('');

  useEffect(() => {
    import(`../../../themes/${theme}/assets/logo.svg`)
      .then(({ default: logo }) => setLogoPath(logo as string))
      .catch((error) => console.error(error));
  }, [theme]);

  const getPageTitle = (): string => {
    if (appTitle?.translationKey && appTitle?.options) {
      return t(appTitle.translationKey, {
        ...appTitle.options,
        defaultValue: t('headings.subscriptionManagement'),
      });
    }
    return t('headings.subscriptionManagement');
  };

  return (
    <header className={styles.header}>
      <div>
        <Link to="/" aria-label={t('general.home')} className={styles.logoLink}>
          <img
            src={logoPath}
            alt={t('header.logoAlt', { theme })}
            className={styles.logo}
          />
        </Link>
      </div>
      <Typography
        level="body"
        component="h1"
        textAlign="center"
        textTransform="uppercase"
      >
        {getPageTitle()}
      </Typography>
      <div className={styles.controls}>
        <JoyLink
          level="bodyLg"
          href={import.meta.env.REACT_APP_PRODUCT_HUB_URL}
          underline="none"
          startDecorator={<LayoutDashboardIcon />}
        >
          {t('general.productHub')}
        </JoyLink>
        {claims ? (
          <JoyLink
            level="bodyLg"
            href={logoutUrl}
            underline="none"
            startDecorator={<UserIcon />}
          >
            {t('authentication.logOut')}
          </JoyLink>
        ) : (
          <JoyLink
            level="bodyLg"
            href="/bff/login"
            underline="none"
            startDecorator={<UserIcon />}
          >
            {t('authentication.logIn')}
          </JoyLink>
        )}
        <LanguageSelector />
        <Helmet>
          <html lang={i18n.language} />
        </Helmet>
      </div>
    </header>
  );
};

export default Header;
