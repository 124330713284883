import DataList from 'components/global/DataList/DataList';
import DataListItem from 'components/global/DataListItem/DataListItem';
import { useTranslation } from 'react-i18next';
import { SubscriptionModel } from 'redux/services/api.generated';
import { DocumentLanguage } from 'types/general';
import { formatDate } from '../../../../util/util';
import styles from './SubscriptionDetails.module.css';

interface SubscriptionDetailsProps {
  subscriptionDetails: SubscriptionModel | undefined;
  className?: string;
}

const SubscriptionDetails = ({
  subscriptionDetails,
  className,
}: SubscriptionDetailsProps) => {
  const { t } = useTranslation();

  const documentLanguages = subscriptionDetails
    ? subscriptionDetails.languages
        .map((lang) => t(`documentLanguages.${lang as DocumentLanguage}`))
        .join(', ')
    : '';

  if (!subscriptionDetails) {
    return null;
  }

  let wrapperClasses = styles.dataListWrapper;
  if (className) {
    wrapperClasses += ` ${className}`;
  }

  return (
    <DataList className={wrapperClasses}>
      <DataListItem
        label={t('labels.subscription')}
        value={subscriptionDetails.subscriptionId}
      />
      <DataListItem
        label={t('labels.status')}
        value={t(`subscriptionStatuses.${subscriptionDetails.subscriptionStatus}`)}
      />
      <DataListItem
        label={t('labels.organization')}
        value={subscriptionDetails.subscriberName}
      />
      <DataListItem
        label={
          subscriptionDetails.contactPersons.length === 1
            ? t('labels.contactPerson.singular')
            : t('labels.contactPerson.plural')
        }
        value={subscriptionDetails.contactPersons.join(', ')}
      />
      {subscriptionDetails.languages && (
        <DataListItem label={t('labels.languages')} value={documentLanguages} />
      )}
      {subscriptionDetails.contractDate && (
        <DataListItem
          label={t('labels.contractDate')}
          value={formatDate(subscriptionDetails.contractDate)}
        />
      )}
      {subscriptionDetails.serviceLevel ? (
        <DataListItem
          label={t('labels.serviceLevel')}
          value={subscriptionDetails.serviceLevel}
        />
      ) : (
        <DataListItem
          label={t('labels.services')}
          value={subscriptionDetails.documentServices.join(', ')}
        />
      )}
      <DataListItem
        label={t('labels.users')}
        value={subscriptionDetails.userCount}
      />
    </DataList>
  );
};

export default SubscriptionDetails;
