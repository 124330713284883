import { useGetBffUserQuery } from 'redux/services/bffUserApi';
import NotLoggedInMessage from 'components/pages/NotLoggedInMessage/NotLoggedInMessage';
import SilentLogin from 'components/util/SilentLogin/SilentLogin';
import { PropsWithChildren, useState } from 'react';
import Spinner from 'components/global/Spinner/Spinner';
import { allowedUserRoles } from 'util/constants';
import AccessDeniedPage from 'components/pages/AccessDeniedPage/AccessDeniedPage';

const LoginHandler = ({ children }: PropsWithChildren) => {
  const { data: claims, isFetching, isError, refetch } = useGetBffUserQuery();

  const hasMatchingRole = claims?.some(
    (claim) => claim.type === 'role' && allowedUserRoles.includes(claim.value),
  );

  const [isSilentLoginCompleted, setIsSilentLoginCompleted] = useState(false);

  const onSilentLoginCompleted = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      void refetch();
    }
    setIsSilentLoginCompleted(true);
  };

  if (claims) {
    return hasMatchingRole ? <>{children}</> : <AccessDeniedPage />;
  }

  if (isError && !isSilentLoginCompleted) {
    return (
      <>
        <Spinner />
        <SilentLogin onCompleted={onSilentLoginCompleted} />
      </>
    );
  }

  if (isFetching || !isSilentLoginCompleted) {
    return <Spinner />;
  }

  return <NotLoggedInMessage />;
};

export default LoginHandler;
